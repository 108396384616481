interface ContentDataInterface {
    en: string[];
    de: string[];
    [key: string]: string[];
}

interface ReleaseNotesDataInterface {
    version: string;
    date: string;
    content: ContentDataInterface;
}

const ReleaseNotesData: ReleaseNotesDataInterface[] = [
    {
        version: "1.0.0",
        date: "24.06.2024",
        content: {
            en: [
                "General Platform - UI Design",
                "Search and linking of applications",
                "Functions of Tiles & Tabs",
                "SNOW Integration",
                "User Feedback",
            ],
            de: [
                "Allgemeine Plattform - UI-Design",
                "Suche und Verknüpfung von Anwendungen",
                "Funktionen von Kacheln und Registerkarten",
                "SNOW-Integration",
                "Benutzerfeedback",
            ],
        },
    },
    {
        version: "1.1.0",
        date: "29.07.2024",
        content: {
            en: [
                "Conducting a guided tour on the Tile Page",
                "About me@eon and release version",
                "Brand Evolution Landing Page/Tiles",
                "Brand Evolution Header",
                "Brand Evolution Footer",
            ],
            de: [
                "Durchführen einer geführten Tour auf der tile seite",
                "Über me@eon und Release-Version",
                "Markenentwicklung Landing Page / Kacheln",
                "Markenentwicklung Header",
                "Markenentwicklung Fußzeile",
            ],
        },
    },
    {
        version: "1.2.0",
        date: "30.08.2024",
        content: {
            en: [
                "Addition of new Applications (Gertrud, Notification Portal, Zuko, EON Brand Space, PKI Portal, Mail DL, Journeys/Events, MyGenius).",
                "Meeting room additional features to show current booking and modify/cancel.",
            ],
            de: [
                "Hinzufügen neuer Applications (Gertrud, Meldungsportal, Zuko, EON Brand Space, PKI Portal, Mailverteiler, Fahrten und Veranstalltungen, MyGenius).",
                "Zusätzliche Raumbuchungen Funktionen zum Anzeigen der aktuellen Buchung und zum Ändern/Stornieren.",
            ],
        },
    },
];

export default ReleaseNotesData;
