import { Option } from "../search-filters/SearchFilters";
import { FilterSetters } from "./elsearch.domain";
import { findDropDown } from "../../domain/info";
const DataSourceFilters = ({
    setters,
    dataSourceFilters,
    searchIndex=[],
}: {
    setters: FilterSetters;
    dataSourceFilters: any[];
    searchIndex?: string[];
}) => {
    const {
        setIsLoading,
        setResultsToDisplay,
        setResultCount,
        setDataType,
        setSearchIndex,
    } = setters;

    const isSingleSelect = true;
    function onChange(selectedData: Option[]) {
        setIsLoading(true);
        setResultsToDisplay([]);
        setResultCount(0);
        let searchIndexs: string[] = [];
        selectedData.forEach((val: Option) => {
            const newArry = val.value.split(",");
            searchIndexs = [...searchIndexs, ...newArry];
        });
        setSearchIndex(searchIndexs);
        setDataType("allWithFilter");
    }
    const options = findDropDown();

    const handleCheckboxChange = (option: Option) => {
        onChange([option]);
    };

    return (
        <div className="filter-list-wrapper">
            {options.map((opt, index) => {
                const { value, label, icon } = opt;
                const FilterObjArr: any = dataSourceFilters.filter(
                    (obj: any) => obj.key === value
                );

                const filterObj = FilterObjArr[0] || { doc_count: 0 };
                const isChecked = searchIndex.some(
                    (selectedOption) => selectedOption === value
                );
                return (
                    <label
                        htmlFor={value}
                        key={`${index.toString()}`}
                        className={`filter-opt ${isChecked ? "filter-opt--selected" : ""}`}
                    >
                        <div className="current-opt-left">
                            <input
                                id={value}
                                type={isSingleSelect ? "radio" : "checkbox"}
                                checked={isChecked}
                                name="advance-page-filters"
                                onChange={() => handleCheckboxChange(opt)}
                            />
                            {icon && (
                                <object
                                    data={require(
                                        `../../assets/el-search-images/${icon}.svg`
                                    )}
                                    type="image/svg+xml"
                                />
                            )}
                            <span className="">{label}</span>
                        </div>
                        {filterObj?.doc_count && (
                            <div className="">
                                {filterObj.doc_count > 100
                                    ? "99+"
                                    : filterObj.doc_count}
                            </div>
                        )}
                    </label>
                );
            })}
        </div>
    );
};

export default DataSourceFilters;
