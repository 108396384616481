import axios, { AxiosResponse } from "axios";
import EnvironmentConfig from "../config/EnvironmentConfig";

interface ApiResponse {
    result: {
        number: string;
        message: string;
    };
}

export const raiseIncident = async (description?: string) => {
    const msalIdData = localStorage.getItem("msal.account.keys") || "";
    const msalID = JSON.parse(msalIdData)[0] || "";
    const userEmail = JSON.parse(localStorage.getItem(msalID) || "")?.username;
    const userKID = userEmail.split("@")[0];

    const requestObject = {
        url: `${EnvironmentConfig.serviceUrl}/service-mgmt-api/v1/inc/create`,
        method: "POST",
        headers: {
            Authorization: `Bearer ${localStorage.getItem("snowBearerToken") || ""}`,
            "Content-Type": "application/json",
        },
        data: JSON.stringify({
            caller_id: userKID,
            contact_number: userEmail,
            service: "",
            short_description: "Me8EON",
            description: description,
            external_ticket_id: "INF192344",
            category: "User Service Request",
            sub_category: "Degraded/Service",
            contact_type: "Email",
            reported_by: userKID,
            location_id: "",
            configuration_item: "DECT Manager",
            assignment_group: "infosys-snow-operations-2",
            file_name: "Test doc - Copy.txt",
            file_data: "VGhpcyBpcyBhIHRlc3QgYXR0YWNobWVudC4NCg==",
        }),
    };
    return await axios(requestObject)
        .then((response: AxiosResponse<ApiResponse>) => {
            return response?.data?.result;

            // {
            //     "result": {
            //         "number": "INC3184802",
            //         "message": "Incident created successfully"
            //     }
            // }
        })
        .catch((error) => {
            console.log(error);
        });
};
