import { emptyDataObject, SourceInfo } from "../info";
import { uppercaseFirstLetterOnly } from "../../util/strings";
import { dateFormat } from "../../util/date";

export const jiraInfo: SourceInfo = {
    match: "jira",
    dropDown: {
        label: "JIRA",
        value: "jira-prod",
        icon: "jira",
    },
    dateFilter: (index: string, { start, now }) => ({
        bool: {
            must: [
                { term: { _index: index } },
                {
                    range: {
                        last_updated: {
                            gte: start,
                            lte: now,
                            format: "yyyy-MM-dd",
                        },
                    },
                },
            ],
        },
    }),
    titleAndTarget: (result) => ({
        title: `${result?.issue}:${result?.summary}`,
        target: result?.url,
        source: "JIRA",
    }),
    makeDataObject: (result) => ({
        ...emptyDataObject,
        id: result.id,
        index: result.index,
        icon: "jira",
        link: result.url || result.self,
        name: `${result.issue}: ${result.summary}`,
        source: "JIRA",
        status: uppercaseFirstLetterOnly(result.status),
        desc: result.description || result.body || result.comments,
        createdBy: result.created_by,
        lastUpdate: dateFormat(result.last_updated),
        render: "markdown",
    }),
};
