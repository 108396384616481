import React from "react";
import { Message } from "./ai.types";
import { useJwtTokens } from "../diagnostics/diagnostics";
import environmentConfig from "../../config/EnvironmentConfig";
import SummariseToIncidentClientSingleton from "./clients/ai.createIncident.client";
import { defaultAzureAiConfig } from "./clients/ai.base.client";
import { AiRagClient } from "./clients/ai.rag.client";

export type OutputAndSource = {
    output: string;
    source: string;
};

export type AiClientWithRag = ( history: Message[], query: string , language: string) => Promise<OutputAndSource>
export type AiClientSummariseChatHistoryToIncident = ( history: Message[], reason: string) => Promise<OutputAndSource>

export type AiClient = {
  aiClientWithRag: AiClientWithRag,
  aiClientSummariseChatHistoryToIncident: AiClientSummariseChatHistoryToIncident
}

const AiRagContext = React.createContext<AiClient | undefined>(
    undefined
);

export function AiRagProvider({
    children,
}: {
    children: React.ReactNode;
}) {

    const aiConfig = {
        config: defaultAzureAiConfig(environmentConfig["gatewayURL"] + "/proxy/"),
        tokenOrApiKey: useJwtTokens().token || "",
    };

    const aiSummariseClient  = new SummariseToIncidentClientSingleton(aiConfig);
    const aiRagClient = new AiRagClient(aiConfig);

    const aiClientSummariseChatHistoryToIncident = async (history: Message[], reason: string) => aiSummariseClient.summariseToIncident(history, reason);
    const aiClientWithRag = async(history: Message[], query: string, language: string) => aiRagClient.aiClientWithRag(history, query, language);

    return (
        <AiRagContext.Provider value={{aiClientSummariseChatHistoryToIncident,aiClientWithRag }}>
            {children}
        </AiRagContext.Provider>
    );
}

export function useAiWithRag(): AiClient {
    const client = React.useContext(AiRagContext);
    if (!client)
        throw new Error("useAiWithRag must be used within a AiRagProvider");
    return client;
}
