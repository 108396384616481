import { useTranslation } from "react-i18next";
import "./SearchBooking.scss";
import {
    EonUiDropdown,
    EonUiDropdownOption,
    EonUiInput,
} from "@eon-ui/eon-ui-components-react";
import { EonUiInputCustomEvent } from "@eon-ui/eon-ui-components";
import useRoomBookingStore from "../../../store/roomBookingStore";
import { MEETING_ROOM_TYPES, MeetingRoomType } from "../RoomBookingInterface";

const SearchBooking: React.FC = () => {
    const { t } = useTranslation("room-booking");
    const { searchType, setSearchQuery, setSearchType } = useRoomBookingStore();
    const meeting_room_type: MeetingRoomType[] = MEETING_ROOM_TYPES;

    return (
        <div className="search-container">
            <div className="free-text">
                <EonUiInput
                    type="text"
                    size="small"
                    className="tab-input"
                    label={t("search")}
                    labelOutside
                    placeholder={t("manageBooking.searchPlaceholder")}
                    onValueChanged={(e: EonUiInputCustomEvent<string>) =>
                        setSearchQuery(e.target.value.toLowerCase())
                    }
                />
            </div>
            <div className="booking-type">
                <EonUiDropdown
                    placeholder="dropdown"
                    onValueChanged={(e) => setSearchType(e.target.value)}
                    value={searchType}
                    name="dropdown"
                    size="small"
                    label={t("type")}
                    labelOutside={true}
                >
                    {meeting_room_type.map((val, index) => (
                        <EonUiDropdownOption
                            placeholder="option"
                            key={`${index.toString()}`}
                            value={val.name}
                            label={val.name}
                        />
                    ))}
                </EonUiDropdown>
            </div>
        </div>
    );
};

export default SearchBooking;
