import { TimeService } from "../domain/useTime";
import {
    calcDataRangeFilters,
    CalcDataRangeFilters,
    ExtractResultFn,
    extractResultsFromAxiosResponse,
    extractResultCountFromAxiosResponse,
    extractResultThen,
    mostRelevant,
} from "./search";
import { AxiosStatic } from "axios";
import { filterQuery, FilterQuery, ResultCountByType } from "../domain/info";
import { QueryTypeAnd } from "../interfaces/ElasticSearchInterface";
import EnvironmentConfig from "../config/EnvironmentConfig";

export type RawCallElasticSearchFn = (
    url: string,
    body: any,
    apiKey: string | null
) => Promise<any>;
export type ApiKeyFn = () => string | null;
export function defaultApiKeyFn(): string | null {
    return localStorage.getItem("dls-apikey");
}
export type SearchContext = {
    timeService: TimeService;
    apikey: ApiKeyFn;
    indicies: string;
    elasticsearchUrl: (indicies: string) => string;
    rawCallElasticSearch: RawCallElasticSearchFn;
    extractResults: QueryTypeAnd<ExtractResultFn>;
    extractResultCount?: (response: any) => ResultCountByType[];
    calcDataRangeFilters: CalcDataRangeFilters;
    filterQuery: FilterQuery;
    boosts?: string[];
};

//  elasticSearchDomain is likely to be one of
//  https://c3224bc073f74e73b4d7cec2bb0d5b5e.westeurope.azure.elastic-cloud.com:9243/
// https://f0571c62200b4d249a4c6750ab7f4716.westeurope.azure.elastic-cloud.com:9243/
export const defaultSearchContext = (
    axios: AxiosStatic,
    apiKey?: string,
): SearchContext => ({
    timeService: () => new Date(),
    apikey: () => apiKey ?? defaultApiKeyFn(),
    indicies: EnvironmentConfig.indicies as string,
    elasticsearchUrl: (actualIndicies) =>
        `${EnvironmentConfig.elasticSearchBaseUrl}/${actualIndicies}/_search`,
    rawCallElasticSearch: async (url: string, body: any, apiKey) =>
        axios.post(url, body, {
            headers: { Authorization: `ApiKey ${apiKey}` },
        }),
    extractResults: {
        knn: extractResultsFromAxiosResponse,
        boostAndPhrase: extractResultsFromAxiosResponse,
        keywords: extractResultThen(
            extractResultsFromAxiosResponse,
            mostRelevant(0.7)
        ),
    },
    extractResultCount: extractResultCountFromAxiosResponse,
    calcDataRangeFilters,
    filterQuery,
    boosts: [
        "fulltext",
        "issue^5",
        "summary^3",
        "description^2",
        "comments^1",
        "title^5",
        "body^3",
        "name^2",
        "text^1",
    ],
});
