import { emptyDataObject, SourceInfo } from "../info";

export const confluenceInfo: SourceInfo = {
    match: "confluence",
    dropDown: {
        label: "Confluence",
        value: "confluence-prod",
        icon: "confluence",
    },
    dateFilter: (index, { start, now }) => ({
        bool: {
            must: [
                { term: { _index: index } },
                {
                    range: {
                        last_updated: {
                            gte: start,
                            lte: now,
                            format: "yyyy-MM-dd",
                        },
                    },
                },
            ],
        },
    }),
    titleAndTarget: (result) => ({
        title: result?.title,
        target: result?.url,
        source: "Confluence",
    }),
    makeDataObject: (result) => ({
        ...emptyDataObject,
        id: result.id,
        index: result.index,
        icon: "confluence",
        link: result.url,
        name: result.title,
        source: "Confluence",
        desc: result.body,
        lastUpdate: result.last_updated,
        render: "html",
    }),
};
