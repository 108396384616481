import { useState } from "react";
import {
    EonUiInput,
    EonUiButton,
    EonUiForm,
} from "@eon-ui/eon-ui-components-react";
import useSearchStore from "../../store/searchStore";
import { useTranslation } from "react-i18next";
import { TabDataInterface } from "../../interfaces/TileInterface";
import { uppercaseFirstLetterOnly } from "../../util/strings";
const NewTabComponent = ({ favTabs }: { favTabs: TabDataInterface[] }) => {
    const { t } = useTranslation();
    const [tabName, setTabName] = useState("");
    const [saveError, setSaveError] = useState(false);

    const { addNewTab, showModal, setPrefData } = useSearchStore();

    const inputSubmit = (e?: any) => {
        e && e.preventDefault();
        const isExistAlready = favTabs.some(
            (obj) => obj.name.toLowerCase() === tabName.toLowerCase()
        );
        if (tabName.length >= 2 && tabName.length < 21 && !isExistAlready) {
            addNewTab([
                ...favTabs,
                {
                    name: uppercaseFirstLetterOnly(tabName),
                    count: 0,
                    active: false,
                },
            ]);
            setPrefData();
        } else {
            setSaveError(true);
        }
    };

    return (
        <EonUiForm onFormSubmit={inputSubmit} className="new-tab-wrapper">
            <EonUiInput
                type="text"
                value={tabName}
                placeholder={t("moveTabAndTiles.enterTabName")}
                autocomplete={false}
                onValueChanged={(e) => {
                    setTabName(e.target.value);
                    setSaveError(false);
                }}
                errorMessage={t("moveTabAndTiles.tabNameError")}
                invalid={saveError}
            ></EonUiInput>
            <div className="common-button-wrapper">
                <EonUiButton
                    fullWidthOnMobile
                    text={t("save")}
                    rank="primary"
                    size="small"
                    onClick={inputSubmit}
                ></EonUiButton>
                <EonUiButton
                    fullWidthOnMobile
                    text={t("cancel")}
                    rank="secondary"
                    size="small"
                    onClick={() => showModal("")}
                ></EonUiButton>
            </div>
        </EonUiForm>
    );
};
export default NewTabComponent;
