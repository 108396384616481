import { EnvConfigs } from "./ConfigInterface";

const gingcoClientId =
    process.env.REACT_APP_GINGCO_CLIENT_ID || "me-at-eon-test";

const otherConfigs: EnvConfigs = {
    local: {
        snowClientId: "5ab0593c7a9444cb845e8b13f7610baf",
        appIndex: "apps-dev",
        indicies:
            "jira-prod,confluence-prod,gitlab-prod,mygenius-prod,sharepoint-prod",
        gingcoClientId,
        AiAccess: ["ME8EON_POC_USERS", "ME8EON_POC_MANAGERS"],
    },
    dev: {
        snowClientId: "5ab0593c7a9444cb845e8b13f7610baf",
        appIndex: "apps-dev",
        indicies:
            "jira-prod,confluence-prod,gitlab-prod,mygenius-prod,sharepoint-prod",
        gingcoClientId,
        AiAccess: ["ME8EON_POC_USERS", "ME8EON_POC_MANAGERS"],
    },
    qa: {
        snowClientId: "25a0dab6ab914e7a962cff0f47ceb0df",
        appIndex: "apps-dev",
        indicies:
            "jira-prod,confluence-prod,gitlab-prod,mygenius-prod,sharepoint-prod",
        gingcoClientId,
        AiAccess: ["ME8EON_POC_USERS", "ME8EON_POC_MANAGERS"],
    },
    pp: {
        snowClientId: "25a0dab6ab914e7a962cff0f47ceb0df",
        appIndex: "apps-prod",
        indicies: "jira-prod,confluence-prod,gitlab-prod,mygenius-prod,sharepoint-prod",
        gingcoClientId,
        AiAccess: ["APL213643-me8eon-Ai-assistance", "APL213643-me8eon-User"],
    },
    prod: {
        snowClientId: "620785b3bab846e8acaaefd44bf53234",
        appIndex: "apps-prod",
        indicies: "jira-prod,confluence-prod,gitlab-prod,mygenius-prod,sharepoint-prod",
        gingcoClientId,
        AiAccess: ["APL213643-me8eon-User"],
    },
};

export default otherConfigs;
