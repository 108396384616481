import { create } from "zustand";
import {
    TileDataInterface,
    TabDataInterface,
} from "../interfaces/TileInterface";
import { getSearchResultsForProd } from "../services/ElasticSearchAPI-prod";
import { getCorrectedData } from "../util/store-utils";
import { dataParser } from "./tempDataParser";
import { getQuestionData } from "../services/chatBotApi";
import EnvironmentConfig from "../config/EnvironmentConfig";
export interface FavData {
    favApps: TileDataInterface[];
    favTabs: TabDataInterface[];
    favLang: string;
    tourTaken: boolean;
}

interface SearchState {
    data: TileDataInterface[];
    loading: boolean;
    isPageLoading: boolean;
    error: string | null;
    prefError: string | null;
    isEditMode: boolean;
    isSearchFocused: boolean;
    displayedModal: string;
    currentTourId: string;
    favData: FavData;
    userId: string;
    searchRequest: () => void;
    setUserId: (userId: string) => void;
    resultUpdate: (data: TileDataInterface[]) => void;
    searchFailure: (error: string) => void;
    showModal: (modal: string) => void;
    startTour: (tourId: string) => void;
    firstTourResponse: (response: string) => void;
    toggleEditMode: (isEditEnabled: boolean) => void;
    updateSearchData: () => void;
    updateFavObject: (data: {
        favApps: TileDataInterface[];
        favTabs: TabDataInterface[];
    }) => void;
    toggleFavourite: (data: TileDataInterface) => void;
    addNewTab: (newTabArray: TabDataInterface[]) => void;
    fetchUserPref: (localAccountId: string | undefined) => void;
    setPrefData: (currentLang?: string) => void;
    fetchSearchData: (search: string) => void;
    setSearchData: (data: any) => void;
    fetchAdvanceSearchData: (search: string) => void;
    setInputFocused: (focused: boolean) => void;
}

const sessionCheck = localStorage.getItem("favData");
const sessionData = sessionCheck ? JSON.parse(sessionCheck) : {};

const initialState: Omit<
    SearchState,
    | "searchRequest"
    | "resultUpdate"
    | "searchFailure"
    | "showModal"
    | "startTour"
    | "firstTourResponse"
    | "toggleEditMode"
    | "updateFavObject"
    | "updateSearchData"
    | "toggleFavourite"
    | "addNewTab"
    | "fetchUserPref"
    | "setPrefData"
    | "setInputFocused"
    | "fetchSearchData"
    | "setSearchData"
    | "fetchAdvanceSearchData"
    | "setUserId"
> = {
    data: [],
    loading: false,
    isPageLoading: false,
    error: null,
    prefError: null,
    isEditMode: false,
    isSearchFocused: false,
    displayedModal: "",
    currentTourId: "",
    userId: "dev_test_user", //should be kept empty once login issue is fixed
    favData: {
        favApps: [],
        favTabs: [],
        favLang: "en",
        tourTaken: false,
        ...sessionData,
    },
};

const useSearchStore = create<SearchState>((set, getState) => ({
    ...initialState,
    searchRequest: () => set({ loading: true }),
    setUserId: (userId) => set({ userId }),
    resultUpdate: (data) =>
        set((state) => {
            const dataWithStatus = data.map((resp: any) => {
                return {
                    ...resp,
                    status: state.favData.favApps.some(
                        (item) => item.alias === resp.alias
                    ),
                };
            });
            return { loading: false, data: dataWithStatus };
        }),
    searchFailure: (error) => set({ loading: false, error }),
    showModal: (modal) => set({ displayedModal: modal }),
    startTour: (tourId) => set({ currentTourId: tourId }),
    setInputFocused: (focused: boolean) => set({ isSearchFocused: focused }),
    firstTourResponse: (response) =>
        set((state) => ({
            displayedModal: "",
            favData: {
                ...state.favData,
                tourTaken: response === "start" || response === "skip",
            },
        })),
    toggleEditMode: (isEditEnabled) => set({ isEditMode: isEditEnabled }),
    updateFavObject: (data) =>
        set((state) => ({ favData: { ...state.favData, ...data } })),
    updateSearchData: () =>
        set((state) => {
            const dataWithStatus = state.data.map((resp: any) => {
                return {
                    ...resp,
                    status: state.favData.favApps.some(
                        (item) => item.alias === resp.alias
                    ),
                };
            });
            return { data: dataWithStatus };
        }),
    toggleFavourite: (tile) =>
        set((state) => {
            const { alias, status } = tile;
            const appObj = state.favData.favApps.find(
                (obj) => obj.alias === alias
            );
            const { cat, seq } = { cat: "Favourite", seq: 0, ...appObj };
            const itemExists = state.favData.favApps.some(
                (obj) => obj.alias === alias
            );

            const existingFavLength = state.favData.favApps.filter(
                (obj) => obj.cat === "Favourite"
            ).length;

            const newFavApps = itemExists
                ? getCorrectedData(state.favData.favApps, alias, cat, seq)
                : [
                      ...state.favData.favApps,
                      {
                          ...tile,
                          cat: "Favourite",
                          seq: existingFavLength + 1,
                          status: true,
                      },
                  ];

            const favTabNotExist = state.favData.favTabs.length === 0;
            let tabData = [...state.favData.favTabs];
            let currentTourId = "";
            let isSearchFocused = true;

            if (favTabNotExist) {
                tabData = [{ active: true, count: 1, name: "Favourite" }];
                currentTourId = "tour2";
                isSearchFocused = false;
            } else {
                const presentedInTab = tabData.findIndex(
                    (obj) => obj.name === cat
                );
                const indexInTab = presentedInTab >= 0 ? presentedInTab : 0;
                const existingCount = tabData[indexInTab]?.count;
                const condition = status
                    ? existingCount - 1
                    : existingCount + 1;
                tabData[indexInTab] = {
                    active: tabData[indexInTab].active,
                    count: condition,
                    name: tabData[indexInTab].name,
                };
            }

            return {
                favData: {
                    ...state.favData,
                    favTabs: tabData,
                    favApps: newFavApps,
                },
                currentTourId,
                isSearchFocused,
                data: state.data.map((obj) => ({
                    ...obj,
                    ...(obj.alias === alias && { status: !itemExists }),
                })),
            };
        }),
    addNewTab: (newTabArray) =>
        set((state) => ({
            favData: { ...state.favData, favTabs: newTabArray },
            displayedModal: "",
        })),
    fetchUserPref: async (localAccountId) => {
        set({ isPageLoading: true });
        try {
            const existingSession = localStorage.getItem("favData");
            let response = existingSession ? JSON.parse(existingSession) : null;
            if (localStorage.getItem("favData") === null) {
                const url = `${EnvironmentConfig.gatewayURL}/api/getUserPref`;
                const requestData = {
                    method: "POST",
                    body: JSON.stringify({
                        User: localAccountId,
                    }),
                };
                const data = await fetch(url, requestData);
                const dataJson = await data.json();

                const favDataResponse = dataJson.PrefData
                    ? JSON.parse(dataJson.PrefData)
                    : {};

                //temp code to support old fav apps
                response = dataParser(favDataResponse);

                if (response.PrefData) {
                    localStorage.setItem("favData", JSON.stringify(response));
                }
            }

            set((state) => ({
                isPageLoading: false,
                favData: { ...state.favData, ...response },
                displayedModal: response.tourTaken ? "" : "firstTour",
                userId: localAccountId,
            }));
        } catch (error: any) {
            set({ isPageLoading: false, prefError: error });
        }
    },
    setPrefData: async (currentLang?: string) => {
        const currentState = getState();
        const newData = currentLang
            ? { ...currentState.favData, favLang: currentLang }
            : currentState.favData;

        // temp session storage: as tile items are getting missing on reload
        localStorage.setItem("favData", JSON.stringify(newData));
        try {
            const url = `${EnvironmentConfig.gatewayURL}/api/setUserPref`;
            const userId = currentState.userId;
            const requestData = {
                method: "POST",
                body: JSON.stringify({
                    User: userId,
                    PrefData: JSON.stringify(newData),
                }),
            };

            const response = await fetch(url, requestData);
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            const data = await response.json();
            if (data.isSuccess === "True") {
                console.log("saved successfully!");
            }
        } catch (error: any) {
            set({ isPageLoading: false, prefError: error });
        }
    },
    fetchSearchData: async (query: string) => {
        set({ loading: true });
        const currentState = getState();
        try {
            const response = await getSearchResultsForProd(query);
            const dataWithStatus = response.map((resp: any) => {
                return {
                    ...resp,
                    status: currentState.favData.favApps.some(
                        (item) => item.alias === resp.alias
                    ),
                };
            });

            set({ loading: false, data: dataWithStatus });
        } catch (error: any) {
            set({ loading: false, error: error });
        }
    },
    setSearchData: (data: any) => {
        set({ data: data });
    },
    fetchAdvanceSearchData: async (query: string) => {
        set({ loading: true });
        try {
            const response = await getQuestionData(query);
            console.log("response", response);

            set({ loading: false, data: response });
        } catch (error: any) {
            set({ loading: false, error: error });
        }
    },
}));

export default useSearchStore;
