/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useState } from "react";
import "./LanguageSwitch.scss";
import { EonUiIcon } from "@eon-ui/eon-ui-components-react";
import { languages } from "./LangInterface";
import { useTranslation } from "react-i18next";
import useSearchStore from "../../store/searchStore";
import useClickOutside from "../../util/useClickOutside";

const LanguageSwitch = () => {
    const { setPrefData } = useSearchStore();
    const [isOpen, setIsOpen] = useState(false);
    const { t, i18n } = useTranslation();

    const onClickOutside = () => {
        isOpen && setIsOpen(false);
    };

    useClickOutside("tileTour3", onClickOutside);

    const onLanguageChange = useCallback((code: string) => {
        localStorage.setItem("i18nextLng", code);
        i18n.changeLanguage(code);
        setPrefData(code);
        setIsOpen(false);
    }, []);

    return (
        <div className="dropdown-overlay" id="tileTour3">
            <button
                data-testid="languageSwitchIcon"
                className={`action-wrapper ${!isOpen ? "action-closed" : ""}`}
                aria-label="Navigate to "
                role="menuitem"
                tabIndex={0}
                onClick={() => setIsOpen(!isOpen)}
            >
                <EonUiIcon
                    title={t("switchLanguage")}
                    placeholder="globe icon"
                    size="normal"
                    name="lang_select"
                />
            </button>
            <div className="overlay-wrapper" data-testid="languageSwitchPopup">
                <div className={`dropdown-body ${isOpen ? "open" : ""}`}>
                    <div
                        className="action-wrapper"
                        onClick={() => setIsOpen(!isOpen)}
                    >
                        <span> {isOpen && t("switchLanguage")} </span>
                        <EonUiIcon
                            title={t("switchLanguage")}
                            placeholder="globe icon"
                            size="normal"
                            name="lang_select"
                        />
                    </div>
                    {languages.map((language, index: number) => {
                        const isSelected = language.code === i18n.language;
                        return (
                            <button
                                data-testid={`${language.code}-${index}`}
                                key={`key-${language.code}`}
                                className={`dropdown-item ${isSelected ? "dropdown-item--highlight" : ""}`}
                                onClick={() => onLanguageChange(language.code)}
                            >
                                <div className="option-val">
                                    {language.name}
                                </div>
                                {isSelected && (
                                    <EonUiIcon
                                        placeholder="tick-mark"
                                        size="normal"
                                        className="turquoise-icon"
                                        name="radio_checkbox_tick"
                                    />
                                )}
                            </button>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default LanguageSwitch;
