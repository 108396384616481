import otherConfigs from "./OtherConfigs";
import { Env, EnvConfigs } from "./ConfigInterface";

const envConfig: EnvConfigs = {
    local: {
        gatewayURL: "https://dev-me8on-mvp.eon.tools",
        gingcoURL: "https://dev-me8on-mvp.eon.tools",
        elasticSearchBaseUrl: "https://f0571c62200b4d249a4c6750ab7f4716.westeurope.azure.elastic-cloud.com:9243",
        serviceUrl: "https://api-nonprod.eon.com/edt/itsm/dev",
        gingcoRedirectURL: "https://eon-test.gingco-estate.com",
    },
    dev: {
        gatewayURL: "",
        gingcoURL: "",
        elasticSearchBaseUrl: "https://f0571c62200b4d249a4c6750ab7f4716.westeurope.azure.elastic-cloud.com:9243",
        serviceUrl: "https://api-nonprod.eon.com/edt/itsm/dev",
        gingcoRedirectURL: "https://eon-test.gingco-estate.com",
    },
    qa: {
        gatewayURL: "",
        gingcoURL: "",
        elasticSearchBaseUrl: "https://f0571c62200b4d249a4c6750ab7f4716.westeurope.azure.elastic-cloud.com:9243",
        serviceUrl: "https://api-nonprod.eon.com/edt/itsm/preprod",
        gingcoRedirectURL: "https://eon-test.gingco-estate.com",
    },
    pp: {
        gatewayURL: "",
        gingcoURL: "",
        elasticSearchBaseUrl: "https://f0571c62200b4d249a4c6750ab7f4716.westeurope.azure.elastic-cloud.com:9243",
        serviceUrl: "https://api-nonprod.eon.com/edt/itsm/preprod",
        gingcoRedirectURL: "https://eon.gingco-estate.com",
    },
    prod: {
        gatewayURL: "",
        gingcoURL: "",
        elasticSearchBaseUrl: "https://f0571c62200b4d249a4c6750ab7f4716.westeurope.azure.elastic-cloud.com:9243",
        serviceUrl: "https://api.eon.com/edt/itsm",
        gingcoRedirectURL: "https://eon.gingco-estate.com",
    },
};

let env: Env;
switch (process.env.REACT_APP_ENV) {
    case "dev":
        env = "dev";
        break;
    case "qa":
        env = "qa";
        break;
    case "pp":
        env = "pp";
        break;
    case "prod":
        env = "prod";
        break;
    default:
        env = "local";
        break;
}

export default { ...envConfig[env], ...otherConfigs[env] };
