import React, { useEffect, useState } from "react";
import EonLogoSVG from "../../assets/image/logo-eon-red.svg";
import { EonUiLink, EonUiImage } from "@eon-ui/eon-ui-components-react";
import "./footer.scss";
import { useTranslation } from "react-i18next";

const Footer: React.FC = () => {
    const { t } = useTranslation();
    const [currentlang, setCurrentLang] = useState("en");

    useEffect(() => {
        const langInSession = localStorage.getItem("i18nextLng");
        setCurrentLang(langInSession || "en");
    }, [t]);

    const year = new Date().getFullYear();

    return (
        <div className="footer" data-testid="footer">
            <div className="white-section-footer">
                <div className="footer-left-right-spacing">
                    <div className="left-footer">
                        <div className="social-media-icons">
                            <EonUiLink
                                slot="socialmedia"
                                target="_blank"
                                icon="social_facebook"
                                href="https://www.facebook.com/EON"
                            ></EonUiLink>
                        </div>
                        <div className="social-media-icons">
                            <EonUiLink
                                slot="socialmedia"
                                target="_blank"
                                icon="social_instagram"
                                href="https://www.instagram.com/e.on_se"
                            ></EonUiLink>
                        </div>
                        <div className="social-media-icons">
                            <EonUiLink
                                slot="socialmedia"
                                target="_blank"
                                icon="social_twitter"
                                href="https://twitter.com/EON_SE_en"
                            ></EonUiLink>
                        </div>
                        <div className="social-media-icons">
                            <EonUiLink
                                slot="socialmedia"
                                target="_blank"
                                icon="social_youtube"
                                href="https://www.youtube.com/channel/UC4bHYYtolAq_mRtak-57Ohg"
                            ></EonUiLink>
                        </div>
                        <div className="social-media-icons">
                            <EonUiLink
                                slot="socialmedia"
                                target="_blank"
                                icon="social_linked_in"
                                href="https://www.linkedin.com/company/e-on/"
                            ></EonUiLink>
                        </div>
                    </div>
                    <div className="mid-footer">
                        <div className="footer-links">
                            <EonUiLink
                                data-testid="imprint"
                                size="small"
                                slot="links"
                                target="_blank"
                                text={t("footer.imprint")}
                                hideIcon={true}
                                href={`https://www.eon.com/${currentlang}/imprint.html`}
                            ></EonUiLink>
                        </div>
                        <div className="footer-links">
                            <EonUiLink
                                data-testid="privacy"
                                size="small"
                                slot="links"
                                target="_blank"
                                text={t("footer.privacyPolicy")}
                                hideIcon={true}
                                href={`https://www.eon.com/${currentlang}/privacy.html`}
                            ></EonUiLink>
                        </div>
                        <div className="footer-links">
                            <EonUiLink
                                data-testid="terms-use"
                                size="small"
                                slot="links"
                                target="_blank"
                                text={t("footer.termsofUse")}
                                hideIcon={true}
                                href={`https://www.eon.com/${currentlang}/terms-use.html`}
                            ></EonUiLink>
                        </div>
                        <div className="footer-links">
                            <EonUiLink
                                data-testid="contact"
                                size="small"
                                slot="links"
                                target="_blank"
                                text={t("footer.contact")}
                                hideIcon={true}
                                href={`https://www.eon.com/${currentlang}/contact.html`}
                            ></EonUiLink>
                        </div>
                        <div className="footer-links">
                            <EonUiLink
                                data-testid="pki"
                                size="small"
                                slot="links"
                                target="_blank"
                                text={t("footer.PKI")}
                                hideIcon={true}
                                href={`https://www.eon.com/${currentlang}/pki.html`}
                            ></EonUiLink>
                        </div>
                        <div className="footer-links">
                            <EonUiLink
                                id="tileTour2"
                                data-testid="about-me8eon"
                                size="small"
                                slot="links"
                                target="_blank"
                                text={t("footer.aboutMe8EON")}
                                hideIcon={true}
                                href={`/about-me8eon`}
                            ></EonUiLink>
                        </div>
                    </div>
                    <div className="right-footer">
                        <div className="eon-copyright">
                            {`© E.ON SE ${year}`}
                        </div>
                        <EonUiImage
                            height="24px"
                            width="82px"
                            slot="logo"
                            src={EonLogoSVG}
                            alt="E.ON Logo"
                            title="E.ON Logo"
                            lazy
                        ></EonUiImage>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;
