import { useTranslation } from "react-i18next";
import { FilterSetters } from "./elsearch.domain";
import { DataType } from "./ElSearchAdvanceHelpers";
import { EonUiLink } from "@eon-ui/eon-ui-components-react";
import EnvironmentConfig from "../../config/EnvironmentConfig";

type SelectFilterButtonProps = {
    isReset?: boolean;
    setters: FilterSetters;
    textkey: string;
    clazz?: string;
    dataType: DataType;
    value: string[] | "app";
    id?: "default";
    clearActiveFilter?: () => void;
};
export function SelectFilterButton({
    id,
    isReset,
    setters,
    clazz,
    value,
    textkey,
    dataType,
    clearActiveFilter,
}: SelectFilterButtonProps) {
    const {
        setIsLoading,
        setResultsToDisplay,
        setResultCount,
        setDataType,
        setSearchIndex,
        SetAdditionalFilters,
    } = setters;
    function filterHandler() {
        setIsLoading(true);
        setResultsToDisplay([]);
        setResultCount(0);
        setDataType(dataType);
        clearActiveFilter && clearActiveFilter();

        if (value.length === 0) {
            SetAdditionalFilters({});
        }
        let index;
        if (value === "app") {
            index = [EnvironmentConfig.appIndex.toString()];
        } else {
            index = value;
        }
        setSearchIndex(index);
    }
    const { t } = useTranslation();

    return isReset ? (
        <EonUiLink
            placeholder="reload-img"
            icon="reload"
            className="reload-icon"
            onClick={filterHandler}
            title={t("filterPage.filterMenu.reset")}
            size="normal"
            href="javascript:void(0)"
            scheme="red500"
        ></EonUiLink>
    ) : (
        <button id={id} className={`tablinks ${clazz}`} onClick={filterHandler}>
            {t(textkey)}
        </button>
    );
}
