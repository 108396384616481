import INITIAL_URL from "../config/EnvironmentConfig";
import { isLocalHost } from "../util/common-util";

const pathResolver = (API: string, isGingco: boolean, params: string) => {
    let urlInitial;
    if (isLocalHost()) {
        urlInitial = isGingco ? INITIAL_URL.gingcoURL : INITIAL_URL.gatewayURL;
    } else {
        urlInitial = "";
    }
    const url = `${urlInitial}/${API}${params ?? ""}`;
    return url;
};
export default pathResolver;
