import { EonUiLink } from "@eon-ui/eon-ui-components-react";
import React, { useState, useEffect } from "react";
import "./TypingEffect.scss";

interface ITypingEffectProps {
    text: string;
    speed: number;
    effecttEnd: () => void;
    className: string;
}

const TypingEffect: React.FC<ITypingEffectProps> = ({
    text,
    speed,
    effecttEnd,
    className,
}) => {
    const [displayText, setDisplayText] = useState("");
    const [linksArray, setLinksArray] = useState<string[]>([]);

    useEffect(() => {
        let currentIndex = -1;
        const interval = setInterval(() => {
            setDisplayText((prevText) => {
                if (currentIndex < text.length) {
                    if (currentIndex < 0) {
                        return text[0];
                    } else {
                        return prevText + text[currentIndex];
                    }
                } else {
                    clearInterval(interval);
                    effecttEnd();
                    return prevText;
                }
            });
            currentIndex = currentIndex + 1;
        }, speed);
        return () => clearInterval(interval);
    }, [text, speed]);

    useEffect(() => {
        const regex = /href="(.*?)"/g;
        let matches;
        const links = [];
        while ((matches = regex.exec(text)) !== null) {
            links.push(matches[1]);
        }
        setLinksArray(links);
    }, [text]);

    return (
        <>
            {linksArray && linksArray.length > 0 && (
                <div className="link-section">
                    <div className="link-heading">
                        <span className="">Link</span>
                    </div>

                    {linksArray.map((link, index) => (
                        <div key={index} className="link-wrapper">
                            <div className="link">
                                <a
                                    href={link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {link}
                                </a>
                            </div>
                            <div className="link-buttons">
                                <EonUiLink
                                    icon="copy"
                                    onClick={() => {
                                        navigator.clipboard.writeText(link);
                                    }}
                                    title="Copy Link"
                                    href="javascript:void(0)"
                                />
                                <EonUiLink
                                    icon="link_external"
                                    target="_blank"
                                    title="Open Link"
                                    href={link}
                                />
                            </div>
                        </div>
                    ))}
                </div>
            )}

            <p
                className={`ai-response ${className}`}
                dangerouslySetInnerHTML={{ __html: displayText }}
            />
        </>
    );
};

export default TypingEffect;
