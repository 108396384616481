import React from "react";
import {
    EonUiAccordion,
    EonUiAccordionPane,
    EonUiBulletList,
    EonUiBulletListItem,
} from "@eon-ui/eon-ui-components-react";
import ReleaseNotesData from "./ReleaseNotesData";
import { useTranslation } from "react-i18next";

const ReleaseNotes: React.FC = () => {
    const { t, i18n } = useTranslation("about-us");
    const heading = t("releaseNotes.accordianHeading");

    return (
        <EonUiAccordion
            mode="single"
            key={i18n.language}
            data-testid="releaseNotes"
        >
            {ReleaseNotesData.slice()
                .reverse()
                .map((notes, notesKey) => (
                    <EonUiAccordionPane
                        pane-title={heading.replace("<version>", notes.version)}
                        slot={heading.replace("<version>", notes.version)}
                        key={`${notes.version} - ${notesKey.toString()}`}
                    >
                        <p>{notes.date}</p>
                        <EonUiBulletList>
                            {notes.content[i18n.language].map(
                                (content, key) => (
                                    <EonUiBulletListItem
                                        role="li"
                                        text={content}
                                        highlight={false}
                                        key={`${content} - ${key.toString()}`}
                                    ></EonUiBulletListItem>
                                )
                            )}
                        </EonUiBulletList>
                    </EonUiAccordionPane>
                ))}
        </EonUiAccordion>
    );
};

export default ReleaseNotes;
