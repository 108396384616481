import React, { useEffect } from "react";
import {
    EonUiTableCell,
    EonUiTableRow,
    EonUiTableBody,
    EonUiTableHeadCell,
    EonUiTableHeadRow,
    EonUiTableHead,
    EonUiDataTable,
    EonUiLink,
} from "@eon-ui/eon-ui-components-react";
import { useTranslation } from "react-i18next";
import "./TableComponent.scss";
import { MapObjDeInterface, MapObjEnInterface } from "./TableInterface";

export type TableAction = {
    icon: string;
    onClick: (data: any) => void;
};

type Props = {
    rows: any;
    cols: any;
    headerMaps: any;
    showPagination: boolean;
};

type CellProps = {
    row: any;
    column: string;
};
function RegularCell({ row, column }: Readonly<CellProps>) {
    return <>{row[column]}</>;
}

function PortalUrlCell({ row, column }: Readonly<CellProps>) {
    return (
        <EonUiLink
            icon="link_external"
            href={row[column]}
            target="_blank"
            size="small"
        />
    );
}

function ActionsCell({ row, column }: Readonly<CellProps>) {
    return (
        <div className="action-cell">
            {(row[column] as TableAction[]).map((action) => (
                <EonUiLink
                    key={action.icon}
                    icon={action.icon}
                    onClick={() => action.onClick(row)}
                    size="small"
                />
            ))}
        </div>
    );
}

function CellRenderer({ row, column }: CellProps) {
    let element: JSX.Element;
    switch (column) {
        case "portalURL":
            element = <PortalUrlCell row={row} column={column} />;
            break;
        case "actions":
            element = <ActionsCell row={row} column={column} />;
            break;
        default:
            element = <RegularCell row={row} column={column} />;
            break;
    }
    return element;
}

const TableComponent: React.FC<Props> = ({ rows, cols, headerMaps, showPagination }) => {
    const { t } = useTranslation();

    useEffect(() => {
        setTimeout(() => {
            // paging data
            const pagingData: HTMLElement | undefined | null = document
                .querySelector("#eon-data-table")
                ?.shadowRoot?.querySelector("div > eon-ui-pagination")
                ?.shadowRoot?.querySelector(
                    "div > div:nth-child(1) > eon-ui-dropdown"
                )
                ?.shadowRoot?.querySelector("label > eon-ui-text");
            if (pagingData) {
                pagingData.innerHTML = t("dataTable.pagingData");
            }

            // dropdown option
            const dropdownOption1 = document
                .querySelector("#eon-data-table")
                ?.shadowRoot?.querySelector("div > eon-ui-pagination")
                ?.shadowRoot?.querySelector(
                    "div > div:nth-child(1) > eon-ui-dropdown"
                )
                ?.shadowRoot?.querySelector(
                    "div.eonui-dropdown-wrapper.eonui-small > select > option"
                );
            if (dropdownOption1) {
                (dropdownOption1 as HTMLOptionElement).label =
                    t("dataTable.pagingData") + ":";
                dropdownOption1.innerHTML = t("dataTable.pagingData") + ":";
            }

            const dropdownOption2 = document
                .querySelector("#eon-data-table")
                ?.shadowRoot?.querySelector("div > eon-ui-pagination")
                ?.shadowRoot?.querySelector(
                    "div > div:nth-child(1) > eon-ui-dropdown"
                )
                ?.shadowRoot?.querySelector(
                    "div.eonui-dropdown-wrapper.eonui-small > label:nth-child(6)"
                );
            if (dropdownOption2) {
                dropdownOption2.innerHTML = t("dataTable.pagingData") + ":";
            }

            const dropdownOption3 = document
                .querySelector("#eon-data-table")
                ?.shadowRoot?.querySelector("div > eon-ui-pagination")
                ?.shadowRoot?.querySelector(
                    "div > div:nth-child(1) > eon-ui-dropdown"
                )
                ?.shadowRoot?.querySelector(
                    "div.eonui-dropdown-wrapper.eonui-small > label:nth-child(3)"
                );
            if (dropdownOption3) {
                dropdownOption3.innerHTML = t("dataTable.pagingData") + ":";
            }

            //page of
            const mapObjEn: MapObjEnInterface = {
                items: t("dataTable.itemString"),
                of: t("dataTable.ofString"),
            };
            const mapObjDe: MapObjDeInterface = {
                Objekte: t("dataTable.itemString"),
                von: t("dataTable.ofString"),
            };
            const getPagingItemVal = document
                .querySelector("#eon-data-table")
                ?.shadowRoot?.querySelector("div > eon-ui-pagination")
                ?.shadowRoot?.querySelector(
                    "div > div.eonui-paging__column.eonui-paging__column--pagination-text > eon-ui-text"
                );
            const result =
                localStorage.getItem("i18nextLng") !== "en"
                    ? getPagingItemVal?.innerHTML.replace(
                          /\b(?:items|of)\b/gi,
                          (matched: string) => mapObjEn[matched]
                      )
                    : getPagingItemVal?.innerHTML.replace(
                          /\b(?:Objekte|von)\b/gi,
                          (matched: string) => mapObjDe[matched]
                      );
            if (getPagingItemVal) {
                getPagingItemVal.innerHTML = result ?? "";
            }

            //page next
            const pagingNext = document
                .querySelector("#eon-data-table")
                ?.shadowRoot?.querySelector("div > eon-ui-pagination")
                ?.shadowRoot?.querySelector("#paginationAddedInfo");
            const resultNext =
                localStorage.getItem("i18nextLng") !== "en"
                    ? pagingNext?.innerHTML.replace(
                          /\b(?:of)\b/gi,
                          (matched: string) => mapObjEn[matched]
                      )
                    : pagingNext?.innerHTML.replace(
                          /\b(?:von)\b/gi,
                          (matched: string) => mapObjDe[matched]
                      );
            if (pagingNext) {
                pagingNext.innerHTML = resultNext ?? "";
            }
        }, 250);
    }, [t]);

    return (
        <EonUiDataTable
            paging={showPagination}
            zebra={true}
            placeholder="Data table"
            size="medium"
            id="eon-data-table"
        >
            <EonUiTableHead placeholder="Data table head">
                <EonUiTableHeadRow placeholder="Data table head row">
                    {cols?.map((header: string, index: number) => (
                        <EonUiTableHeadCell
                            key={`${index.toString()}`}
                            className="table-font-header"
                            placeholder="Data table cell"
                        >
                            {header}
                        </EonUiTableHeadCell>
                    ))}
                </EonUiTableHeadRow>
            </EonUiTableHead>
            <EonUiTableBody placeholder="Data table body">
                {rows?.map((row: any, rowIndex: number) => (
                    <EonUiTableRow
                        key={`${rowIndex.toString()}`}
                        placeholder="Data table row"
                    >
                        {headerMaps.map(
                            (column: string, columnIndex: number) => {
                                return (
                                    <EonUiTableCell
                                        key={`${rowIndex.toString()}.${columnIndex.toString()}`}
                                        className="table-font"
                                        placeholder="Data table cell"
                                    >
                                        <CellRenderer
                                            row={row}
                                            column={column}
                                        />
                                    </EonUiTableCell>
                                );
                            }
                        )}
                    </EonUiTableRow>
                ))}
            </EonUiTableBody>
        </EonUiDataTable>
    );
};

export default TableComponent;
