import { MakeQueryForEsFn } from "./search";

export const getReqBody = (
    searchTerm: string,
    dateFilters: any,
    boosts: string[],
    resultSize?: number,
    searchAfter?: object
) => {
    const newQuery = searchTerm.replace(/\^/g, '');
    return {
        size: resultSize,
        // from: 0,
        _source: {
            excludes: [
                "full_text_embeddings",
                "full_text",
                "file",
                "pdf file path",
                "pdf",
            ],
        },
        query: {
            bool: {
                should: [
                    {
                        multi_match: {
                            query: newQuery,
                            fields: boosts,
                            type: "phrase",
                            slop: 1, // Allows for up to 1 intervening words between the terms
                            boost: 2.0,
                        },
                    },
                    {
                        multi_match: {
                            query: newQuery,
                            fields: newQuery,
                        },
                    },
                    {
                        query_string: {
                            query: `${newQuery}*`,
                            escape: true,
                        },
                    },
                ],
            },
            ...(dateFilters.length > 0 && {
                query: { bool: { ...dateFilters } },
            }),
        },
        sort: [
            {
                _score: {
                    order: "desc",
                },
            },
            {
                _id: {
                    order: "asc",
                },
            },
        ],
        aggregations: {
            count: {
                terms: {
                    field: "_index",
                },
            },
        },
        ...(searchAfter && { search_after: searchAfter }),
    };
};
export const makeBoostAndPhraseQuery =
    (boosts: string[]): MakeQueryForEsFn =>
    (
        resultSize: number,
        searchTerm: string,
        dateFilters: any,
        searchAfter?: object
    ) =>
        getReqBody(searchTerm, dateFilters, boosts, resultSize, searchAfter);
