import {
    AdditionalFilters,
    ESApiFinalResponseInterface,
    QueryType,
} from "../interfaces/ElasticSearchInterface";
import React from "react";
import { mapK } from "../util/kleislis";
import { FilterDateRange, ResultCountByType } from "../domain/info";
import { FindIdFn, interleave, removeDuplicates } from "../util/arrays";
import { TimeService } from "../domain/useTime";
import { SearchContext } from "./searchContext";

export type SearchRequest = {
    searchTerm: string;
    searchIndexes: string[];
    additionalFilters?: AdditionalFilters;
    setResultCountByTypeWrapper?: (count: ResultCountByType) => void;
    searchAfter?: object;
    expectedRecordSize?: number;
    resultsToDisplay?: any[];
};
export type SearchFn<T> = (searchRequest: SearchRequest) => Promise<T[]>;

export function composeSearchFns<T>(
    findIdFn: FindIdFn<T>,
    ...fns: SearchFn<T>[]
): SearchFn<T> {
    const unique = removeDuplicates(findIdFn);
    return async (searchRequest: SearchRequest) => {
        const arrays = await mapK(fns, async (fn) => fn(searchRequest));
        const interwoved = interleave(arrays);
        const result = unique(interwoved);
        return result;
    };
}

export const SearchFnContext = React.createContext<SearchFn<any> | undefined>(
    undefined
);

export function useSearchFn(): SearchFn<ESApiFinalResponseInterface> {
    const searchFn = React.useContext(SearchFnContext);
    if (!searchFn)
        throw new Error(
            "useSearchFn must be used within a SearchFnContext.Provider"
        );
    return searchFn;
}

export type SearchFnProviderProps = {
    searchFn: SearchFn<ESApiFinalResponseInterface>;
    children: React.ReactNode;
};
export function SearchFnProvider({
    searchFn,
    children,
}: SearchFnProviderProps) {
    return (
        <SearchFnContext.Provider value={searchFn}>
            {children}
        </SearchFnContext.Provider>
    );
}

export const dateFormatForEs = (date: Date): string => {
    const yyyy = date.getFullYear();
    const mm = (date.getMonth() + 1).toString().padStart(2, "0"); // Months start at 0!
    const dd = date.getDate().toString().padStart(2, "0");

    return `${yyyy}-${mm}-${dd}`;
};

export type CalcDataRangeFilters = (
    additionalFilters: AdditionalFilters | undefined,
    timeService: TimeService
) => FilterDateRange | undefined;

export const calcDataRangeFilters: CalcDataRangeFilters = (
    additionalFilters: AdditionalFilters | undefined,
    timeService: TimeService
): FilterDateRange | undefined => {
    if (
        !additionalFilters ||
        !additionalFilters.selectedTime ||
        !additionalFilters.selectedTime[0].value
    )
        return undefined;

    const subtractDay = additionalFilters.selectedTime[0].value;
    const subtractDayNum = parseInt(subtractDay, 10);

    if (isNaN(subtractDayNum))
        throw new Error(`Invalid subtractDay value: ${subtractDay}`);

    const today = timeService();
    const dateOffset = 24 * 60 * 60 * 1000 * subtractDayNum;
    const startDate = new Date(today.getTime() - dateOffset);

    return { start: dateFormatForEs(startDate), now: dateFormatForEs(today) };
};

export type ExtractResultFn = (
    extra: Record<string, any>,
    response: any
) => ESApiFinalResponseInterface[];

export type ExtractResultCountFn = (
    response: any
) => ResultCountByType[];

export function extractResultThen(
    extract: ExtractResultFn,
    fn: (result: ESApiFinalResponseInterface[]) => ESApiFinalResponseInterface[]
): ExtractResultFn {
    return (extra, response) => fn(extract(extra, response));
}
export function mostRelevant(
    min: number
): (result: ESApiFinalResponseInterface[]) => ESApiFinalResponseInterface[] {
    if (min <= 0 || min > 1)
        throw new Error(
            `Invalid min value: ${min}. Must be in a range of more than 0 up to and including 1`
        );
    return (raw) => {
        const actualMin = raw.length > 0 ? raw[0].score * min : 0;
        return raw.filter((r) => r.score >= actualMin);
    };
}

export const extractResultsFromAxiosResponse: ExtractResultFn = (
    extra: Record<string, any>,
    response: any
): ESApiFinalResponseInterface[] => {
    const hits = response?.data?.hits?.hits;
    if (!Array.isArray(hits)) {
        console.warn(
            "Unexpected response from Elasticsearch. No hits",
            response
        );
        return [];
    }
    return hits.map((hit: any) => ({
        ...extra,
        ...hit._source,
        highlight: hit.highlight,
        index: hit._index,
        id: hit._id,
        score: hit._score,
    }));
};

export const extractResultCountFromAxiosResponse: ExtractResultCountFn = (
    response: any
): ResultCountByType[] => {
    const aggregations = response?.data?.aggregations?.count?.buckets;
    if (!Array.isArray(aggregations)) {
        console.warn(
            "Unexpected response from Elasticsearch. No aggregations",
            response
        );
        return [];
    }
    return aggregations.map((item: { key: string, doc_count: number }) => ({ [item.key]: item.doc_count }));
};

export type MakeQueryForEsFn = (
    resultSize: number,
    searchTerm: string,
    dateFilters: any[],
    searchAfter?: object
) => any;

export const search =
    (
        context: SearchContext,
        queryType: QueryType,
        makeQuery: MakeQueryForEsFn
    ): SearchFn<ESApiFinalResponseInterface> =>
    async (
        searchRequest: SearchRequest
    ): Promise<ESApiFinalResponseInterface[]> => {
        const {
            timeService,
            indicies,
            elasticsearchUrl,
            apikey,
            rawCallElasticSearch,
            extractResults,
            filterQuery,
            calcDataRangeFilters,
        } = context;
        const { searchTerm, searchIndexes, additionalFilters, expectedRecordSize, searchAfter } = searchRequest;
        const requstedDataSize = additionalFilters ? 200 : 5;
        const resultSize = expectedRecordSize ? expectedRecordSize : requstedDataSize;
        const dateRange = calcDataRangeFilters(additionalFilters, timeService);
        const dateFilters = dateRange
            ? filterQuery(searchIndexes, dateRange)
            : [];
        const actualIndicies =
            searchIndexes?.length > 0 ? searchIndexes.join(",") : indicies;
        const response = await rawCallElasticSearch(
            elasticsearchUrl(actualIndicies),
            makeQuery(resultSize, searchTerm, dateFilters, searchAfter),
            apikey()
        );
        return extractResults[queryType]({ queryType }, response);
    };