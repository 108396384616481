import {Message} from "./ai.types";
import {getFrom, MapOrDefault} from "../../util/map";

export type MakePromptFn = (
    history: Message[],
    searchResult: AiSearchResult | undefined,
    query: string,
    language: string
) => Message[];

export type QuestionatorSearchResult = {
    index: string;
    type: string;
    question: string;
    keyfield: string;
    answer: string;
    content: string;
};
export type AppsProdSearchResult = {
    index: string;
    app_name: string;
    url: string;
};

export type AiSearchResult =
    | QuestionatorSearchResult
    | AppsProdSearchResult
    | undefined;

export type QueryAndSearchResultToPromptFn<T> = (
    query: string,
    searchResult: T,
    language: string
) => string;

export const promptsForOfficeBuddyIndex = (
    query: string,
    searchResult: QuestionatorSearchResult,
    language: string
) => `
    Matched Q&A:
    Question: ${searchResult.keyfield}
    Answer: ${searchResult.answer}
    
    CONTEXT: You're company employee support agent. User asked a question that 
    went to the ElasticSearch and found matched document with general answer. 
    
    INSTRUCTIONS: 
    1) See Matched Q&A. There is the Answer to the user's question.
    2) IMPORTANT! Always if user question includes some personal conditions (for example: 
    Where is *my laptop*? Where is *my office*?) respond with general answer. 
    3) If Matched Q&A includes URL, keep URL in the response.
    4) Ensure the response is in natural language, accurate and concise.
    
    Last step- Respond in language: ${language}`;


export const promptsForQuestionatorIndex: MapOrDefault<
    QueryAndSearchResultToPromptFn<QuestionatorSearchResult>
> = {
    qanda: (query, searchResult, language) => ` Matched Q&A:
                Question: ${searchResult.keyfield}
                Answer: ${searchResult.answer}
                                
            -- START DOCUMENT --
             ${(searchResult as any)["content"]}    
            -- END DOCUMENT --
                
            CONTEXT: You're company employee support agent. User asked a question that 
            went to the ElasticSearch and found matched Q&A with content. Q&A that is returned 
            from ElasticSearch has been built using custom "Questioner" app based on the input 
            PDF Doc

            INSTRUCTIONS:
            1) See Matched Q&A. There is the Answer to the user's question.
            2) If Matched Q&A includes URL, keep URL in the response.
            3) Review the Full document content and include it in the response if needed.
            3a) If document has information in points, you can also response in points.
            3b) If document has URL, keep URL in the response.
            4) Ensure the response is in natural language, accurate and concise.
            
            Last step- Respond in language: ${language}`,
    content: (query, searchResult, language) => `
            -- START DOCUMENT CONTENT --
            ${(searchResult as any)["keyfield"]}   
            -- END DOCUMENT --
            
            CONTEXT: You're company employee support agent. User asked a question that 
            went to the ElasticSearch and found matched document with content.

            INSTRUCTIONS:
            1) See Full Document Content. There is the Answer to the user's question
            2) Ensure the response is in natural language, accurate and concise.
            3) If document has information in points, you can also response in points
            4) If you don't know the answer, just say you don't know.
            5) If Matched Q&A includes URL, keep URL in the response.
            
            Last step- Respond in language: ${language}`,
    default: () =>
        `INSTRUCTIONS: Answer to the user based only on the information provided above. 
            If user asks question regarding his own conditions (for example: Where is my laptop? 
            Where is my office?) treat it as general question. If document has information in points, 
            you can also response in points. If you don't know the answer, just say you don't know. 
            Ensure the response is accurate and concise. Respond in user's language, translate if needed.`,
};

export const promptsByIndex: MapOrDefault<
    QueryAndSearchResultToPromptFn<AiSearchResult>
> = {
    "apps-prod": (query, sr, language) => {
        const searchResult = sr as AppsProdSearchResult;
        return ` ElasticSearch Results:
              Title: ${searchResult["app_name"]}
              Url: ${searchResult["url"]}
              
              CONTEXT: You're company employee support agent. User asked a question that 
              went to the ElasticSearch and found matched title of the application that he is 
              looking for. Your job is to tell user the app name and its url that he is looking for. \\n\\n
  
              INSTRUCTIONS:\\n
              1) See the title of the application.\\n
              2) Generate short response based on the matched title and user's question.\\n
              2a) If title includes URL, keep URL in the response.\\n
              4) Ensure the response is in natural language, accurate and concise.\\n
              
              Last step- Respond in language: ${language}`;
    },
    undefined: (query) => `No search result found for ${query}`,
    "questionator-prod": (query, searchResult, language) => {
        const sr = searchResult as QuestionatorSearchResult;
        const finder = getFrom(promptsForQuestionatorIndex)(sr.type);
        return finder(query, sr, language);
    },
    "office-buddy-prod": (query, searchResult, language) => {
        return promptsForOfficeBuddyIndex(query, searchResult as QuestionatorSearchResult, language);
    },
    default: (query, sr) => {
        throw new Error(
            `Index ${sr?.index} not supported in ${JSON.stringify(sr)}`
        );
    },
};
