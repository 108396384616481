import axios from "axios";
import EnvironmentConfig from "../config/EnvironmentConfig";

interface ResponseData {
    id: string;
    name: string;
    api_key: string;
    encoded: string;
    username: string;
    indicies: string;
    query: object;
}

async function fetchAndStoreDLSApiKey(account: {
    username: string;
    idToken?: string;
}) {
    try {
        const resp = await axios.get<ResponseData>(
            `${EnvironmentConfig.gatewayURL}/apikey/${account.username}`,
            {
                headers: {
                    Authorization: `Bearer ${account.idToken}`,
                },
            }
        );
        localStorage.setItem("dls-apikey", resp?.data?.encoded);
    } catch (err) {
        console.log(err);
    }
}

export default fetchAndStoreDLSApiKey;
