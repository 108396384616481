import { emptyDataObject, SourceInfo, TitleTargetAndSource } from "../info";

const languagesMap: Record<string, string> = {
    cs: "Czech",
    de: "Deutsch",
    en: "English",
    es: "Spanish",
    fr: "French",
    it: "Italian",
    ja: "Japanese",
    ko: "Korean",
    nl: "Dutch",
    pl: "Polish",
    pt: "Portuguese",
    ru: "Russian",
    sv: "Swedish",
    tr: "Turkish",
    zh: "Chinese",
};

function getLanguages(languages?: string) {
    if (languages) {
        const langArray = languages.split(",");
        if (langArray.length > 2) {
            const langString = langArray
                .slice(0, 2)
                .map((lang) => languagesMap[lang])
                .join(", ");
            return `${langString} & ${langArray.length - 2} more`;
        } else {
            return langArray.map((lang) => languagesMap[lang]).join(", ");
        }
    } else {
        return null;
    }
}

function getDuration(duration: number): string {
    duration = duration * 86400;

    const days = Math.floor(duration / 86400); // 86400 seconds in a day
    const hours = Math.floor((duration % 86400) / 3600);
    const minutes = Math.floor((duration % 3600) / 60);
    // const remainingSeconds = duration % 60;

    const result = [];

    if (days > 0) {
        result.push(`${days} ${days > 1 ? "days" : "day"}`);
    }
    if (hours > 0) {
        result.push(`${hours} ${hours > 1 ? "hours" : "hour"}`);
    }
    if (minutes > 0) {
        result.push(`${minutes} ${minutes > 1 ? "minutes" : "minute"}`);
    }
    // if (remainingSeconds > 0) {
    //     result.push(
    //         `${remainingSeconds} ${remainingSeconds > 1 ? "seconds" : "second"}`
    //     );
    // }

    return result.join(" ");
}

export const myGeniusInfo: SourceInfo = {
    match: "mygenius-prod",
    dropDown: {
        label: "MyGenius",
        value: "mygenius-prod",
        icon: "mygenius",
    },
    makeDataObject: (result) => ({
        ...emptyDataObject,
        id: result?.ecl_id,
        index: result?.index,
        icon: "mygenius",
        link: `https://eon.edcast.com/insights/ECL-${result.ecl_id}`,
        name: result?.card_title,
        source: `MyGenius - [${result?.ecl_source_name}]`,
        type: result?.standard_card_type,
        desc: result?.card_description,
        duration: getDuration(result?.Duration_HHMM),
        language: getLanguages(result?.card_languages),
        render: "markdown",
    }),
    titleAndTarget: function (): TitleTargetAndSource {
        throw new Error("Function not implemented.");
    },
};
