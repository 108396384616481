export interface CapatityObjInterface {
    capacity_charge_from: number;
    capacity_default: number;
    capacity_max: number;
    cost_default: number;
    cost_extra_seat: number;
    is_default_seating: boolean;
    seating_id: number;
    seating_type_id: number;
    seating_type_name: string;
}
export interface RoomBookingInterface {
    object_id: number;
    object_status_id?: number;
    object_type_id?: number;
    object_subtype_id?: number;
    object_name?: string;
    hotspot_name?: string;
    hotspot_name_position?: string;
    object_short_description?: string;
    object_long_description?: string;
    additional_information?: string;
    currency_code?: string;
    timezone_id?: string;
    media_id?: number;
    position_x?: number;
    position_y?: number;
    geo_latitude?: string;
    geo_longitude?: string;
    assigned_to_user_id?: number;
    permissions?: [string];
    hierarchy_location_ids?: [number];
    seating_ids: number[] | CapatityObjInterface[] | any;
    notification_email_addresses?: [];
    external_ids?: [];
    minCapValue?: number;
    //   aggregation_id?: null;
    availability: number;
    is_cluster?: boolean;
    is_equipment?: boolean;
    is_unlimited?: boolean;
}

export interface UnavailabilityDataInterface {
    begin?: BeginEndInterface;
    end?: BeginEndInterface;
    items: ItemInterface[];
    object_id?: number;
}

export interface BeginEndInterface {
    datetime?: string;
    offset?: number;
    timezone_id?: string;
    timestamp?: number;
}

export interface ItemInterface {
    begin?: BeginEndInterface;
    end?: BeginEndInterface;
    unavailability_type_id?: number;
    object_id?: number;
}

// interface GingcoGetObjectsInterface {
//     accessToken: string;
//     data: {
//         objects: RoomBookingInterface[];
//         seatings: CapatityObjInterface[];
//         unavailabilities: UnavailabilityDataInterface;
//     };
//     status: string;
// }

// interface GingcoSaveBookingsInterface {
//     accessToken: string;
//     data: {
//         objects: RoomBookingInterface[];
//         seatings: CapatityObjInterface[];
//         unavailabilities: UnavailabilityDataInterface;
//     };
//     status: string;
// }
export interface UnavailabilityDataInterface {
    begin?: BeginEndInterface;
    end?: BeginEndInterface;
    items: ItemInterface[];
    object_id?: number;
}

export interface AvailabilityTimelineProps {
    interval?: number; // Interval in minutes for the time scale, default to 60 minutes
    unavailabilityInfo: UnavailabilityDataInterface[];
}

export interface ExistBookingInterface {
    start: string; // Time in "HH:mm" format
    end: string; // Time in "HH:mm" format
}

export interface UnavailabilityDataType {
    [key: string]: ErrorCodeType;
}

export type ErrorCodeType = {
    [key: string]: string;
};

export const MEETING_ROOM_TYPES: MeetingRoomType[] = [
    {
        id: 0,
        name: "All",
    },
    {
        id: 2,
        name: "Hot Desk",
    },
    {
        id: 3,
        name: "Meeting Room",
    },
];

export interface MeetingRoomType {
    id: number;
    name: string;
}

export interface BookingData {
    booking_id: number;
    meetings_on: string;
    begin: { timestamp: number; datetime: string };
    end: { timestamp: number; datetime: string };
    topic: string;
    object_name: string;
    object_type_id: number;
    capacity: number;
}

export interface ManageBookingData {
    meeting_id: number;
    meeting_on: string;
    meeting_timing: string;
    meeting_desc: string;
    meeting_location: string;
    meeting_type: string;
    meeting_participants: number;
    actions: any;
}
