import { AiAssistDataInterface } from "./AiAssistInterface";

const serviceIssueList: string[] = [
    "I forgot my password. How can I reset it?",
    "I'm having trouble sending/receiving emails. What should I do?",
    // "My printer isn't printing. Can you help me fix it?",
    // "My MacBook keeps freezing. How can I fix it?",
    // "Having trouble connecting to my remote desktop? Need support?",
];

const knowledgeBaseIssueList: string[] = [
    "What is the objective of me@eon?",
    "What is Compliance?",
    // "What is the company's sick leave policy?",
    // "What are my business hours and where to find it?",
    // "Where can I see my organisation structure?",
];

const AiAssistData: AiAssistDataInterface = {
    welcome: [
        {
            messageContent: [
                {
                    alice: "selfService",
                    messageTitle: "selfServiceTitle",
                    messageContent: "selfServiceContent",
                    icon: "itSupport",
                    issueList: serviceIssueList,
                },
                {
                    alice: "knowledgeBase",
                    messageTitle: "knowledgeBaseTitle",
                    messageContent: "knowledgeBaseContent",
                    icon: "knowledgeBase",
                    issueList: knowledgeBaseIssueList,
                },
                {
                    alice: "internetSearch",
                    messageTitle: "internetSearchTitle",
                    messageContent: "internetSearchContent",
                    icon: "internetSearch",
                    issueList: serviceIssueList,
                },
            ],
        },
    ],
};

export default AiAssistData;
