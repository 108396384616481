import axios from "axios";

export const getQuestionData = async (searchTerm: string) => {
    const url = `https://c3224bc073f74e73b4d7cec2bb0d5b5e.westeurope.azure.elastic-cloud.com:443/questionator_kamil/_search`;

    return axios
        .post(
            url,
            {
                query: {
                    match_phrase_prefix: {
                        question: searchTerm,
                    },
                },
            },
            {
                headers: {
                    Authorization:
                        "ApiKey NWZDNE00d0Jtc3pXTjAwWXBXNU46MTFad2tiUTJSRUNuSldHbmR3Vzc5dw==",
                },
            }
        )
        .then((response) => {
            const suggestions = response.data.hits.hits.map((hit: any) => {
                return {
                    title: hit._source.question,
                };
            });
            return suggestions;
        })
        .catch((error) => {
            console.error("Fetch Error :-S", error);
            return error;
        });
};
