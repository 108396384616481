import { EonUiWebsiteNavigationMainLink } from "@eon-ui/eon-ui-components-react";
import "./AuthComponent.scss";
import { useAuth } from "../../MsalContext";

function AuthComponent() {
    const { account, isExpired, login, logout } = useAuth();

    return (
        <>
            {account && <div className="username">{account.name}</div>}
            <EonUiWebsiteNavigationMainLink
                id="tileTour4"
                icon={account ? "logout" : "user_outline"}
                onClick={() => {
                    account ? logout() : login();
                }}
                href="javascript:void(0)"
            >
                {isExpired && <div className="loggedout-alert"></div>}
            </EonUiWebsiteNavigationMainLink>
        </>
    );
}

export default AuthComponent;
