import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./i18n";
import { EonUiPreloaderSquares } from "@eon-ui/eon-ui-components-react";
import { SearchFnProvider } from "./search/search";
import {
    boostAndPhraseSearch,
} from "./search/all.searchs";
import { defaultSearchContext } from "./search/searchContext";
import axios from "axios";
import {
    defaultFeedbackFn,
    FeedbackProvider,
} from "./components/feedback/feedback";
import { Diagnostics } from "./components/diagnostics/diagnostics";
import { MsalProvider } from "./MsalContext";
import {
    AiRagProvider,
} from "./components/ai/ai.rag.client";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

//it would be great to get this from the Environmental config
//and also setup the right indicies

export const searchContext = defaultSearchContext(axios);
const diagnostics = window.location.href.includes("diagnostics");

const searchFn = boostAndPhraseSearch(searchContext);
root.render(
    <React.StrictMode>
        <MsalProvider>
            <FeedbackProvider
                feedbackFn={defaultFeedbackFn(
                    axios,
                    "feedback"
                )}
            >
                <SearchFnProvider searchFn={searchFn}>
                        <AiRagProvider>
                            <Suspense
                                fallback={
                                    <EonUiPreloaderSquares
                                        placeholder="preloader"
                                        className="pre-loader-gif"
                                    ></EonUiPreloaderSquares>
                                }
                            >
                                <App />
                                {diagnostics && <Diagnostics />}
                            </Suspense>
                        </AiRagProvider>
                </SearchFnProvider>
            </FeedbackProvider>
        </MsalProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
