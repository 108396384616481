import { useTranslation } from "react-i18next";
import "./EditBooking.scss";
import { EonUiButton, EonUiDatePicker, EonUiDropdown, EonUiDropdownOption } from "@eon-ui/eon-ui-components-react";
import useRoomBookingStore from "../../../store/roomBookingStore";
import { generateTimeStrings } from "../../time-picker/TimePicker";
import { getBookingContainerTemplateApi, getTimeLinesApi, updateBookingApi } from "../../../services/roomBooking";
import { useEffect, useState } from "react";
import { convertDurationToMilliseconds, getFormattedDateTime } from "../../../util/date";
import { getAccessToken } from "../../../services/accessToken";

type EditBookingProps = {
    reloadManageBookingList: () => void;
};

const EditBooking = ({reloadManageBookingList}: EditBookingProps) => {

    const {
        currentBookingData,
        setEditBookingModal,
        manageBookingRawData
    } = useRoomBookingStore();
    const { t: t0 } = useTranslation();
    const { t, i18n } = useTranslation("room-booking");
    const [availabilityText, setAvailabilityText] = useState<string>("Unknown");
    const [dateValue, setDateValue] = useState<string>("");
    const [startTime, setStartTime] = useState<string>("");
    const [duration, setDuration] = useState<string>("");
    const [preFillComplete, setPreFillComplete] = useState<boolean>(false);
    const [successErrMsg, setSuccessErrMsg] = useState<string>("");
    const [updateSuccess, setUpdateSuccess] = useState<boolean>(false);
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const date = new Date(currentBookingData.meeting_on);
    const options: Intl.DateTimeFormatOptions = {
        weekday: "long",
        day: "2-digit",
        month: "short",
    };

    useEffect(() => {
        const meeting = getMeetingDetailsById(currentBookingData.meeting_id);
        setTimeout(() => {
            setDateValue(getFormattedDateTime(meeting.begin.timestamp * 1000, "Y-m-d"));
            setDuration(preFillDuration(meeting.begin.timestamp, meeting.end.timestamp));
            setStartTime(getFormattedDateTime(meeting.begin.timestamp * 1000, "H:i"));
            setPreFillComplete(true);
        }, 0);
    }, []);

    useEffect(() => {
        preFillComplete && checkAvailability();
    }, [dateValue, startTime, duration]);

    const preFillDuration = (startTime: number, endTime: number): string => {
        const diffInMinutes = (endTime - startTime) / 60;
        return (diffInMinutes < 60) ? diffInMinutes + " Min" : (diffInMinutes / 60) + " Hr";
    };

    const formattedMeetingDate = date
        .toLocaleDateString("en-GB", options)
        .replace(/ /g, " ");

    const updateBooking = async (meeting_id: number) => {
        setUpdateSuccess(true);
        const actualToken = await getAccessToken();
        const payload = await getUpdateBookingPayload(meeting_id);
        if (!payload) {
            setSuccessErrMsg(t0("apiFailDefaultMsg"));
            setUpdateSuccess(false);
            return;
        }
        const begin = new Date(dateValue + " " + startTime).getTime();
        const end = new Date(dateValue + " " + startTime).getTime() + convertDurationToMilliseconds(duration);
        const format = "Y-m-d H:i:s";

        payload[0].booking_templates[0].begin.timestamp = begin / 1000;
        payload[0].booking_templates[0].begin.datetime = getFormattedDateTime(begin, format, false, timezone);
        payload[0].booking_templates[0].end.timestamp = end / 1000;
        payload[0].booking_templates[0].end.datetime = getFormattedDateTime(end, format, false, timezone);
        payload[0].booking_templates[0].initial_begin.timestamp = begin / 1000;
        payload[0].booking_templates[0].initial_begin.datetime = getFormattedDateTime(begin, format, false, timezone);

        const finalPayload = {
            include: [],
            booking_container_templates: payload,
            dummytoken: "",
            accesstoken: actualToken || "",
            send_mails: true
        };
        const newBookingTemplate = {
            begin: {
                timestamp: begin / 1000,
                datetime: getFormattedDateTime(begin, format, false, timezone)
            },
            end: {
                timestamp: end / 1000,
                datetime: getFormattedDateTime(end, format, false, timezone)
            },
            initial_begin: {
                timestamp: begin / 1000,
                datetime: getFormattedDateTime(begin, format, false, timezone)
            }
        };
        finalPayload.booking_container_templates.booking_templates = newBookingTemplate;
        try {
            const response = await updateBookingApi(finalPayload);
            if (response.status == "success" && response.data.booking_container_results[0].result && !response.data.booking_container_results[0].errors.length) {
                setSuccessErrMsg(t("updateBookingApiSucessMsg"));
                reloadManageBookingList();
            } else {
                setSuccessErrMsg(t0("apiFailDefaultMsg"));
                setUpdateSuccess(false);
                console.log("something went wrong!", response);
            }
        } catch(e) {
            setSuccessErrMsg(t0("apiFailDefaultMsg"));
            setUpdateSuccess(false);
            console.log("something went wrong!", e);
        }
    };

    const getUpdateBookingPayload = async (meeting_id: number) => {
        const meeting = getMeetingDetailsById(meeting_id);
        const actualToken = await getAccessToken();
        const payload = {
            "booking_container_ids": [meeting.booking_container_id],
            "include": [],
            "accesstoken": actualToken || ""
        };
        try {
            const response = await getBookingContainerTemplateApi(payload);
            if (response.status == "success" && response.data.booking_container_templates.length) {
                return response.data.booking_container_templates;
            } else {
                console.log("something went wrong!", response);
            }
        } catch(e) {
            console.log("something went wrong!", e);
        }
    };

    const getMeetingDetailsById = (meeting_id: number) => {
        return manageBookingRawData.find(obj => obj.booking_id === meeting_id);
    };

    const getMeetingDuration = (meeting_id: number) => {
        const meeting = getMeetingDetailsById(meeting_id);
        const diff = (meeting.end.timestamp - meeting.begin.timestamp) / 3600;

        return (diff === 1) ? `${diff} Hour` : `${diff} Hours`;
    };

    const checkAvailability = async () => {
        setSuccessErrMsg("");
        const actualToken = await getAccessToken();
        const meeting = getMeetingDetailsById(currentBookingData.meeting_id);
        const begin = new Date(dateValue + " " + startTime).getTime();
        const end = new Date(dateValue + " " + startTime).getTime() + convertDurationToMilliseconds(duration);
        const format = "Y-m-d H:i:s";
        const payload = {
            object_ids: [meeting.object_id],
            timeframes: [
                {
                    begin: {
                        datetime: getFormattedDateTime(begin, format, false, timezone),
                        timezone_id: meeting.begin.timezone_id,
                    },
                    end: {
                        datetime: getFormattedDateTime(end, format, false, timezone),
                        timezone_id: meeting.end.timezone_id,
                    },
                },
            ],
            dummytoken: "",
            accesstoken: actualToken || ""
        };
        try {
            const response = await getTimeLinesApi(payload);
            if (response.status == "success") {
                setAvailabilityText(prepareAvailabilityText(response.data[0].availability));
            } else {
                console.log("something went wrong!", response);
            }
        } catch (e) {
            console.log("something went wrong!", e);
        }
    };

    const prepareAvailabilityText = (availability: number) => {
        let text: string = "";
        switch (availability) {
            case 0:
                text = t("availability.free");
                break;
            case 1:
                text = t("availability.partialFree");
                break;
            case 2:
                text = t("availability.busy");
                break;
            default:
                text = t("availability.unknown");
        }
        
        return text;
    };

    return (
        <div className="edit-booking-container">
            <div className="heading">{t("manageBooking.reschedule")}</div>
            <div className="title">
                {currentBookingData.meeting_desc}
            </div>
            <div className="other-details">
                <div className="location">
                    <div className="label">{t("manageBooking.meetingLocation")}</div>
                    <div className="loc">
                        {currentBookingData.meeting_location}
                    </div>
                </div>
                <div className="timing">
                    <div className="date">
                        {formattedMeetingDate}
                    </div>
                    <div className="time">
                        {currentBookingData.meeting_timing}
                    </div>
                    <div className="duration">
                        {t("duration")}: {getMeetingDuration(currentBookingData.meeting_id)}
                    </div>
                </div>
            </div>
            <div className="reschedule-to">
                {t("manageBooking.rescheduleTo")}
            </div>
            <div className="form">
                <div className="date dropdown">
                    <EonUiDatePicker
                        placeholder="datepicker"
                        onValueChanged={(e) => {
                            setDateValue(e.target.value);
                        }}
                        value={dateValue}
                        name="date"
                        size="small"
                        label={t("date")}
                        labelOutside={true}
                        maxDaysBefore={0}
                        selectToday={true}
                        dateFormat="dd/MM/yyyy"
                        lang={i18n.language}
                        key={i18n.language}
                    />
                </div>
                <div className="start-time dropdown">
                    <EonUiDropdown
                        data-testid="timePickerRoot"
                        placeholder="00:00"
                        onValueChanged={(e) => {
                            setStartTime(e.target.value);
                        }}
                        value={startTime}
                        name="dropdown"
                        size="small"
                        height="256px"
                        label={t("startingTime")}
                        labelOutside={true}
                    >
                        {generateTimeStrings().map((item) => (
                            <EonUiDropdownOption key={item} value={item} label={item} />
                        ))}
                    </EonUiDropdown>
                </div>
                <div className="duration dropdown">
                <EonUiDropdown
                        placeholder="dropdown"
                        onValueChanged={(e) => {
                            setDuration(e.target.value);
                        }}
                        value={duration}
                        name="dropdown"
                        size="small"
                        label={t("duration")}
                        labelOutside={true}
                    >
                        {[
                            "15 Min",
                            "30 Min",
                            "45 Min",
                            "1 Hr",
                            "2 Hr",
                            "4 Hr",
                            "8 Hr",
                        ].map((val, index) => (
                            <EonUiDropdownOption
                                placeholder="option"
                                key={`${index.toString()}`}
                                value={val}
                                label={val}
                            />
                        ))}
                    </EonUiDropdown>
                </div>
            </div>
            <div className="availability-info" style={{display: successErrMsg ? "none" : ""}}>
                {t("availability.text")}: { availabilityText }
            </div>
            <div className="success-err-msg" style={{display: !successErrMsg ? "none" : ""}}>{successErrMsg}</div>
            <div className="btn">
                <div className="confirm">
                    <EonUiButton text={t0("confirm")} disabled={updateSuccess} onClick={() => updateBooking(currentBookingData.meeting_id)}></EonUiButton>
                </div>
                <div className="cancel">
                    <EonUiButton rank="secondary" text={t0("cancel")} onClick={() => setEditBookingModal(false)}></EonUiButton>
                </div>
            </div>
        </div>
    );
};

export default EditBooking;
