import React, { useEffect, useState } from "react";
import axios from "axios";

interface SvgFromUrlProps {
    url: string;
}

const SVGIcon: React.FC<SvgFromUrlProps> = ({ url }) => {
    const [svgContent, setSvgContent] = useState<string>("");

    useEffect(() => {
        const fetchSvg = async () => {
            try {
                const response = await axios.get(url);
                setSvgContent(response.data);
            } catch (error) {
                if (axios.isAxiosError(error)) {
                    console.error(
                        `Error fetching SVG from URL: ${error.message}`
                    );
                } else {
                    console.error("Error fetching SVG from URL");
                }
            }
        };
        fetchSvg();
    }, [url]);

    return <span dangerouslySetInnerHTML={{ __html: svgContent }} />;
};

export default SVGIcon;
