import React, { useState } from "react";
import "./AiAssistCard.scss";
import {
    MessageContentInterface,
    WelcomeDataInterface,
} from "../AiAssistInterface";
// import { t } from "i18next";
import { useTranslation } from "react-i18next";
import SVGIcon from "../../svg-icon/SVGIcon";

interface ChildProps {
    messages: WelcomeDataInterface[];
    aiChatBotHandler: (index: number) => void;
}
const AiAssistCard: React.FC<ChildProps> = ({ messages, aiChatBotHandler }) => {
    const { t } = useTranslation();
    const [messageBoxIndex, setMessageBoxIndex] = useState(0);

    const handleSendMessage = (index: number) => {
        console.log("Message sent: ", index);
        if (index === 2) {
            // asked by praveen to not do anything for Internet search
            return;
        }
        setMessageBoxIndex(index);
        aiChatBotHandler(index);
    };

    return (
        <div className="card-container">
            {messages[0]?.messageContent?.map(
                (value: MessageContentInterface, index: number) => (
                    <div
                        key={`${index.toString()}`}
                        className={`card-layout ${
                            messageBoxIndex === index ? "highlight" : ""
                        } ${index === 2 ? "disabled" : ""}`}
                        onClick={() => {
                            handleSendMessage(index);
                        }}
                    >
                        <div className="card-row" key={`${index.toString()}`}>
                            <div>
                                <SVGIcon url={`./icons/${value.icon}.svg`} />
                            </div>
                            <span className="eonui-text bot-text">
                                {t(`chatBot.${value.messageTitle}`)}
                            </span>
                        </div>
                        <p className="card-content">
                            {t(`chatBot.${value.messageContent}`)}
                        </p>
                    </div>
                )
            )}
        </div>
    );
};

export default AiAssistCard;
