import React, { useEffect, useState } from "react";
import "@elastic/react-search-ui-views/lib/styles/styles.css";
import "./ElSearchAdvance.scss";
import { EonUiLink } from "@eon-ui/eon-ui-components-react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ElSearchAdvanceCard from "./results/ElSearchAdvanceCard";
import { isQA } from "../../util/common-util";
import { getDataToDisplayFrom, search } from "./ElSearchGetData";
import SearchFiltersTop from "./SearchFiltersTop";
import { DataType, SearchInput } from "./ElSearchAdvanceHelpers";
import { FilterSetters } from "./elsearch.domain";
import LoaderComponent from "../loader-component/LoaderComponent";
import { useSearchFn } from "../../search/search";
import { FeedbackFn, useFeedback } from "../feedback/feedback";
import { useAuth } from "../../MsalContext";
import SearchFiltersRight from "./SearchFiltersRight";

import { ResultCountByType } from "../../domain/info";
import { ReactComponent as AiIcon } from "../../assets/image/ai-icon.svg";
import AiAssist from "../ai-assist/AiAssist";

function NoData({ noMoreData }: { noMoreData?: boolean }) {
    const { t } = useTranslation();
    return (
        <li style={{ textAlign: "center", padding: "4px" }} key={"noData"}>
            {t(`filterPage.filterMenu.${noMoreData ? "noMoreData" : "noData"}`)}
        </li>
    );
}

const ElSearchAdvance: React.FC = () => {
    const [dataType, setDataType] = useState<DataType>("all");
    const [searchIndex, setSearchIndex] = useState<string[]>([]);
    const [additionalFilters, setAdditionalFilters] = useState({});
    const [searchTerm, setSearchTerm] = useState("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [resultCount, setResultCount] = useState<number>(0);
    const [resultCountByType, setResultCountByType] =
        useState<ResultCountByType>({});
    const [resultsToDisplay, setResultsToDisplay] = useState<any[]>([]);
    const [showAiAssist, setShowAiAssist] = useState(false);
    const [showRecords, setShowRecords] = useState<any[]>([]);
    const [scrollCompleted, setScrollCompleted] = useState<boolean>(false);
    const [joinData, setJoinData] = useState<boolean>(false);
    const [lastRecord, setLastRecord] = useState<{
        score: number;
        id: string;
    } | null>(null);
    const { t } = useTranslation();
    const { account, isLimitedAccess } = useAuth();

    const isManager = account?.idTokenClaims?.roles?.includes(
        "ME8EON_POC_MANAGERS"
    );

    const isQaPocUser = isQA() && !isManager;

    function languageChanged() {
        setResultsToDisplay([]);
        setResultCount(0);
        setResultCountByType({});
    }

    function enterPageAndSetSearchFromUrl() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const searchQuery = urlParams.get("q");
        if (searchQuery) setSearchTerm(searchQuery);
        if (!isQaPocUser)
            (document.getElementById("default") as HTMLElement).click();
    }

    useEffect(() => languageChanged(), [t]);
    useEffect(() => {
        enterPageAndSetSearchFromUrl();
    }, []);

    const setters: FilterSetters = {
        setIsLoading,
        setResultsToDisplay,
        setResultCount,
        setResultCountByType: setResultCountByTypeWrapper,
        setDataType,
        setSearchIndex,
        SetAdditionalFilters: setAdditionalFilters,
    };

    const searchFn = useSearchFn();
    const feedbackFn: FeedbackFn = useFeedback();

    useEffect(() => {
        const handleScroll = () => {
            const scrolled =
                window.innerHeight + window.scrollY + 300 >=
                document.body.offsetHeight;
            setScrollCompleted(scrolled);
        };
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    useEffect(() => {
        const oldRecords = joinData ? showRecords : [];
        const jointList = [...oldRecords, ...resultsToDisplay];
        setShowRecords(scrollCompleted ? jointList : resultsToDisplay);
        setScrollCompleted(false);
        setLastRecord(resultsToDisplay[resultsToDisplay.length - 1]);
        setJoinData(false);
    }, [resultsToDisplay]);

    const executeSearch = search(
        searchTerm,
        () => {
            feedbackFn({
                source: "query",
                page: "advanced",
                query: searchTerm,
            });
            return searchFn({
                searchTerm,
                searchIndexes: searchIndex,
                additionalFilters,
                setResultCountByTypeWrapper,
                ...(scrollCompleted &&
                    lastRecord && {
                        searchAfter: [lastRecord.score, lastRecord.id],
                    }),
                expectedRecordSize: 20,
            });
        },
        getDataToDisplayFrom(),
        setters
    );

    useEffect(() => {
        if (!scrollCompleted || resultsToDisplay.length === 0) {
            return;
        }
        const delayDebounceFn = setTimeout(() => {
            executeSearch();
            setJoinData(true);
        }, 500);
        return () => {
            clearTimeout(delayDebounceFn);
        };
    }, [scrollCompleted]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            executeSearch();
        }, 500);
        return () => {
            clearTimeout(delayDebounceFn);
        };
    }, [searchTerm, searchIndex, additionalFilters]);

    function setResultCountByTypeWrapper(count: ResultCountByType) {
        if (searchIndex.length) {
            setResultCountByType({ ...resultCountByType, ...count });
        } else {
            setResultCountByType(count);
        }
    }

    return showAiAssist ? (
        <AiAssist setShowAiAssist={setShowAiAssist} />
    ) : (
        <div className="search-custom-filter-wrapper">
            <Link to="/tile">
                <EonUiLink
                    className="back-button"
                    icon="chevron_small_left"
                    iconPosition="left"
                    placeholder="back"
                    href="javascript:void(0)"
                    text={t("filterPage.filterMenu.backBtn")}
                ></EonUiLink>
            </Link>
            <div className="search-input">
                <SearchInput
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                />
                {!isLimitedAccess && (
                    <button
                        className="chat-bot"
                        onClick={() => setShowAiAssist(true)}
                        title={t("chatBot.chatBotTitle")}
                    >
                        <AiIcon />
                        <div
                            id="tileTour5"
                            data-testid="about-me8eon"
                            className="chat-bot-label"
                            // onClick={() => setShowAiAssist(true)}
                        >
                            Assistance
                        </div>
                    </button>
                )}
            </div>
            <LoaderComponent isLoading={isLoading} isLinear />
            {!isQaPocUser && (
                <SearchFiltersTop setters={setters} dataType={dataType} />
            )}
            <div className="custom-result">
                {!isQaPocUser && searchTerm && (
                    <SearchFiltersRight
                        setters={setters}
                        searchQuery={searchTerm}
                        searchIndex={searchIndex}
                    />
                )}
                {searchTerm && (
                    <ul className="custom-result-container">
                        {showRecords?.map((result, key) => (
                            <ElSearchAdvanceCard
                                result={result}
                                key={`${key.toString()}`}
                                searchTerm={searchTerm}
                            />
                        ))}
                        {!isLoading && resultCount === 0 && (
                            <NoData noMoreData={showRecords.length > 0} />
                        )}
                    </ul>
                )}
            </div>
            {scrollCompleted && (
                <LoaderComponent isLoading={isLoading} isLinear />
            )}
        </div>
    );
};
export default ElSearchAdvance;
