import { TileDataInterface } from "../../interfaces/TileInterface";
import { countByKey } from "../../util/arrays";
import { withLoading } from "../../util/common-util";
import { FilterSetters } from "./elsearch.domain";

export type HasTitleAndIndex = {
    title: string;
    index: string;
};

export const getDataToDisplayFrom =
    () =>
    <T extends HasTitleAndIndex>(
        autocompleteSuggestions: T[]
    ): (T | TileDataInterface)[] => {
        return autocompleteSuggestions.flatMap<T | TileDataInterface>(
            (dataObj: T) => {
                if (dataObj.title && dataObj.title.includes(".pdf")) return [];
                return [dataObj];
            }
        );
    };

export const search =
    <T, Res>(
        searchTerm: string,
        get: () => Promise<T[]>,
        getDataToDisplayFrom: (suggestions: T[]) => Res[],
        setters: FilterSetters
    ) =>
    async () => {
        const { setIsLoading, setResultsToDisplay, setResultCount, setResultCountByType } = setters;
        if (searchTerm === "") {
            setResultCount(0);
            setResultCountByType({});
            return;
        }
        const autocompleteSuggestions = await withLoading(setIsLoading, get);
        const dataToDisplay = getDataToDisplayFrom(autocompleteSuggestions);
        setResultCount(dataToDisplay.length);
        setResultCountByType(countByKey(dataToDisplay, "index" as keyof Res));
        setResultsToDisplay(dataToDisplay);
    };
