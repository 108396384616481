import "./InfoBanner.scss";
import { useTranslation } from "react-i18next";
import { EonUiIcon } from "@eon-ui/eon-ui-components-react";

type InfoBannerProps = {
    background?: string;
    heading?: string;
    color?: string;
    multiLangHeading?: string;
    icon?: string;
};

export default function InfoBanner({
    background,
    heading,
    multiLangHeading,
    color,
    icon,
}: InfoBannerProps) {
    const { t } = useTranslation();
    return (
        <div
            data-testid="infoBannerRootId"
            className="banner-wrapper"
            style={{ background }}
        >
            <div className="banner-heading" style={{ color }}>
                {icon && (
                    <EonUiIcon
                        data-testid="infoBannerIconId"
                        size="normal"
                        name={icon}
                    />
                )}
                {multiLangHeading ? t(multiLangHeading) : heading}
            </div>
        </div>
    );
}
