const excelTypes: string[] = [
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.ms-excel",
];
const presentationTypes: string[] = [
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
];
const documentTypes: string[] = [
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
];
const pdfTypes: string[] = ["application/pdf"];
const noteTypes: string[] = ["application/msonenote"];
const imageTypes: string[] = ["image/png", "image/jpeg", "image/svg+xml"];
const postscriptTypes: string[] = ["application/postscript"];

const types: Record<string, string[]> = {
    PDF: pdfTypes,
    Excel: excelTypes,
    Document: documentTypes,
    Presentation: presentationTypes,
    Note: noteTypes,
    Image: imageTypes,
    PostScript: postscriptTypes,
};

function getMimeType(input?: string | null) {
    if (input) {
        let derivedType = "Other";
        Object.keys(types).forEach((type) => {
            if (types[type].includes(input)) {
                derivedType = type;
            }
        });
        return derivedType;
    }
    return null;
}

export default getMimeType;
