import { EonUiInput } from "@eon-ui/eon-ui-components-react";
import { DropDownInfo, ResultCountByType } from "../../domain/info";
import SearchFilters, { Option } from "../search-filters/SearchFilters";
import { FilterSetters } from "./elsearch.domain";

export type SearchInputProps = {
    searchTerm: string;
    setSearchTerm: (value: string) => void;
};
export function SearchInput({
    searchTerm,
    setSearchTerm,
}: Readonly<SearchInputProps>) {
    return (
        <EonUiInput
            type="text"
            trailingIconName="search"
            placeholder="Search..."
            value={searchTerm}
            onValueChanged={(e) => setSearchTerm(e.target.value)}
        />
    );
}
export type DataType = "" | "all" | "apps" | "allWithFilter";

export type SelectDataTypeProps = {
    options: DropDownInfo[];
    dataType: DataType;
    setters: FilterSetters;
    resultCountByType: ResultCountByType
};
export function SelectDataType({
    options,
    dataType,
    setters,
    resultCountByType
}: SelectDataTypeProps) {
    const {
        setIsLoading,
        setResultsToDisplay,
        setResultCount,
        setDataType,
        setSearchIndex,
    } = setters;
    function onChange(selectedData: Option[]) {
        setIsLoading(true);
        setResultsToDisplay([]);
        setResultCount(0);
        let searchIndexs: string[] = [];
        selectedData.forEach((val: Option) => {
            const newArry = val.value.split(",");
            searchIndexs = [...searchIndexs, ...newArry];
        });
        setSearchIndex(searchIndexs);
        setDataType("allWithFilter");
    }
    return (
        <SearchFilters
            options={options}
            isActive={dataType === "allWithFilter"}
            isSingleSelect={true}
            onChange={onChange}
            label="Data Sources"
            showCount={true}
            resultCountByType={resultCountByType}
            name="data-sources"
        />
    );
}
