import {
    EonUiForm,
    EonUiPreloaderCircle,
} from "@eon-ui/eon-ui-components-react";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { TileDataInterface } from "../../interfaces/TileInterface";
import "./SearchList.scss";
import ListItem from "./ListItem";
import useClickOutside from "../../util/useClickOutside";
import useSearchStore from "../../store/searchStore";
import useOutsideFocus from "../../util/useOutsideFocus";
import { useFeedback } from "../feedback/feedback";
import { useAuth } from "../../MsalContext";
import { useSearchFn } from "../../search/search";

export interface Questionator {
    title: string;
}

const SearchList = ({
    isAdvanceSearch,
    itemClickedCallback,
    queryChange,
    limitedHeight,
    listReverse,
    defaultInputVal,
}: {
    isAdvanceSearch?: boolean;
    itemClickedCallback?: (query: string) => void;
    queryChange?: (query: string) => void;
    limitedHeight?: boolean;
    listReverse?: boolean;
    defaultInputVal?: null | string;
}) => {
    const {
        loading,
        isEditMode,
        isSearchFocused,
        setInputFocused,
        fetchSearchData,
        // setSearchData,
        fetchAdvanceSearchData,
        resultUpdate,
        data: searchData,
    } = useSearchStore();
    const { t } = useTranslation();
    const [query, setQuery] = useState(defaultInputVal || "");
    const navigate = useNavigate();
    const feedbackFn = useFeedback();
    const { isLimitedAccess } = useAuth();
    const [topOffset, setTopOffset] = useState(-46);
    const searchFn = useSearchFn();
    const listRef = useRef<HTMLUListElement>(null);
    const listExistClass = listReverse ? "reverse-list--exist" : "list--exist";

    useEffect(() => {
        setQuery(defaultInputVal || "");
    }, [defaultInputVal]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            queryChange && queryChange(query);
            if (query.trim() !== "" && query.length > 1) {
                if (isAdvanceSearch) {
                    fetchAdvanceSearchData(query);
                    console.log(
                        "searchData",
                        searchData,
                        isSearchFocused,
                        query
                    );
                } else {
                    fetchSearchData(query);
                    const getResult = async () => {
                        const response = await searchFn({
                            searchTerm: query,
                            searchIndexes: ["apps-dev"],
                        });
                        console.log("response", response);
                        // setSearchData(response);
                    };
                    getResult();
                }
            }
        }, 500);
        return () => {
            clearTimeout(delayDebounceFn);
            resultUpdate([]);
        };
    }, [query]);

    useEffect(() => {
        setQuery(defaultInputVal || "");
    }, [defaultInputVal]);

    useLayoutEffect(() => {
        if (listRef.current && listReverse) {
            setTopOffset(
                document.getElementsByClassName("result_ul")[0].clientHeight
            );
        }
    });

    const inputSubmit = (e: any) => {
        console.log("got to input submit", query, isAdvanceSearch);
        if (isAdvanceSearch) {
            if (itemClickedCallback) {
                itemClickedCallback(query);
                setQuery("");
            }
            return;
        }
        feedbackFn({ source: "query", page: "simple", query });

        e.preventDefault();
        if (!isLimitedAccess) {
            iconClickHandler();
        }
    };

    const iconClickHandler = () => {
        if (query && !isLimitedAccess) {
            navigate(`/search-result?q=${query}`);
        }
    };
    const onClickOutside = () => {
        setInputFocused(false);
    };

    const listItemClicked = (question: string) => {
        setQuery("");
        if (itemClickedCallback) {
            itemClickedCallback(question);
        }
    };

    useClickOutside("search-input", onClickOutside);
    const listWrapper = useOutsideFocus<HTMLDivElement>(() => {
        setInputFocused(false);
    });

    return (
        <div
            id="search-input"
            ref={listWrapper}
            className={`search-list-component ${isSearchFocused ? "input--focused" : ""}
                ${query.length > 1 && searchData.length > 0 && isSearchFocused ? listExistClass : ""}`}
        >
            <EonUiForm onFormSubmit={inputSubmit}>
                <input
                    id="tileTour1"
                    className="input-box-wrapper"
                    type="text"
                    value={query}
                    // onBlur={() => setTimeout(() => {
                    //     dispatch(setInputFocused(false));
                    // }, 500)}
                    autoComplete="off"
                    onFocus={() => setInputFocused(true)}
                    onChange={(e) => setQuery(e.target.value)}
                    placeholder={t("searchPage.searchPlaceholder")}
                    title={t("searchPage.searchApps")}
                    disabled={isEditMode}
                />
                {loading && (
                    <div className="loader">
                        <EonUiPreloaderCircle size="small" />
                    </div>
                )}
                {!isLimitedAccess && !isAdvanceSearch && (
                    <button
                        className="search-icon-container"
                        onClick={iconClickHandler}
                        disabled={isEditMode}
                    ></button>
                )}
            </EonUiForm>
            {query.length > 1 && searchData.length > 0 && isSearchFocused && (
                <ul
                    style={{ top: -topOffset }}
                    ref={listRef}
                    className={`result_ul
                        result_ul${limitedHeight ? "limited-height" : ""}
                        ${listReverse ? "result_ul--reverse" : "result_ul--normal"}
                    `}
                >
                    {searchData.map(
                        (
                            result: TileDataInterface | Questionator,
                            index: number
                        ) => (
                            <ListItem
                                resultData={result}
                                key={`${index + 1}`}
                                listItemClicked={(question) =>
                                    listItemClicked(question)
                                }
                            />
                        )
                    )}
                </ul>
            )}
        </div>
    );
};

export default SearchList;
