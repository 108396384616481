import { dateFormat } from "../../util/date";
import getMimeType from "../../util/mimeType";
import { emptyDataObject, SourceInfo } from "../info";

function getDesc(result: any) {
    return result.text && result.text.length > 0 ? result.text : result.url;
}

export const sharepointInfo: SourceInfo = {
    match: "sharepoint",
    dropDown: {
        label: "Sharepoint",
        value: "sharepoint-prod",
        icon: "sharepoint",
    },
    titleAndTarget: (result) => ({
        title: result?.name,
        target: result.url,
        source: "Sharepoint",
    }),
    makeDataObject: (result) => ({
        ...emptyDataObject,
        id: result.id,
        icon: "sharepoint",
        link: result.url,
        name: result.name,
        source: result.type,
        desc: getDesc(result),
        type: getMimeType(result?.mimeType),
        createdAt: dateFormat(result.createdDateTime),
        createdBy: result.createdBy,
        lastUpdate: dateFormat(result.last_updated),
    }),
};
