import React from "react";
import ReleaseNotes from "../../components/release-notes/ReleaseNotes";

const AboutUs: React.FC = () => {
    // const { t } = useTranslation("about-us");

    return (
        <div data-testid="aboutUs">
            <ReleaseNotes />
        </div>
    );
};

export default AboutUs;
