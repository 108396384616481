import TourStart from "../../components/tour-guide/TourStart";
import Modal from "../../components/modal/Modal";
import TourLangOptions from "../../components/tour-lang-options/TourLangOptions";
import { useTranslation } from "react-i18next";
import SearchList from "../../components/search-list";
import TileComponent from "../../components/tile-component";
import TabComponent from "../../components/tab-component";
import NewTabComponent from "../../components/tab-component/NewTabComponent";
import AiAssist from "../../components/ai-assist/AiAssist";
import "./TilePage.scss";
import { ReactComponent as AiIcon } from "../../assets/image/ai-icon.svg";
import Organize from "../../components/organize-tile-page/Organize";
import PersonalizeDashboard from "../../components/organize-tile-page/PersonalizeDashboard";
import { useEffect, useState } from "react";
import LoaderComponent from "../../components/loader-component/LoaderComponent";
import useSearchStore from "../../store/searchStore";
import { useAuth } from "../../MsalContext";

const TilePage = () => {
    const { account, isLimitedAccess } = useAuth();
    const nameArray = account?.name?.split(",") ?? [];
    const firstName = nameArray[1] || nameArray[0];
    const { t } = useTranslation();

    const {
        isPageLoading,
        displayedModal,
        currentTourId,
        isEditMode,
        showModal,
        startTour,
        firstTourResponse,
        fetchUserPref,
        favData,
        setPrefData,
        setUserId,
    } = useSearchStore();
    const { favTabs, favApps } = favData;
    const showTabComponent = favApps.length > 0;
    const [showAiAssist, setShowAiAssist] = useState(false);
    const activeTabObj = favTabs.filter((tab) => tab.active);
    const activeTab = activeTabObj.length > 0 ? activeTabObj[0].name : "";

    useEffect(() => {
        if (account) {
            setUserId(account?.localAccountId);
            fetchUserPref(account?.localAccountId);
        }
    }, [account]);

    const tourAction = (actionName: string) => {
        firstTourResponse(actionName);
        setPrefData();
        if (actionName === "start") {
            startTour("tour1");
        }
    };

    return (
        showAiAssist ? (
            <AiAssist setShowAiAssist={setShowAiAssist} />
        ) : (
            <div className="tilePage">
                <LoaderComponent isLoading={isPageLoading} />
                <TourStart tourId={currentTourId} />
                <Modal
                    toggleModal={!!displayedModal}
                    closeModal={() => showModal("")}
                    isCloseIcon
                    closeOnBackdrop={false}
                >
                    {displayedModal === "firstTour" && (
                        <TourLangOptions tourAction={tourAction} />
                    )}
                    {displayedModal === "addNewTab" && (
                        <NewTabComponent favTabs={favTabs} />
                    )}
                    {displayedModal === "personalizeDashboard" && (
                        <PersonalizeDashboard favTabs={favTabs} favApps={favApps} />
                    )}
                </Modal>
                <div className={`${isEditMode ? "edit-mode--opaque" : ""}`}>
                    <div className="tile-width--limited">
                        <h1
                            title={t("landingPage.title")}
                            key={t("landingPage.title")}
                            className="tile-heading"
                        >
                            {t("landingPage.hello")} {firstName},{" "}
                            {t("landingPage.title")}
                        </h1>
                        <div className="search-wrapper">
                            <div style={{ width: "100%" }}>
                                <SearchList />
                            </div>
                            {!isLimitedAccess && (
                                <button
                                    className="chat-bot"
                                    onClick={() =>
                                        !isEditMode ? setShowAiAssist(true) : null
                                    }
                                    title={t("chatBot.chatBotTitle")}
                                >
                                    <AiIcon />
                                    <div
                                        id="tileTour5"
                                        data-testid="about-me8eon"
                                        className="chat-bot-label"
                                        // onClick={() => setShowAiAssist(true)}
                                    >
                                        Assistance
                                    </div>
                                </button>
                            )}
                        </div>
                    </div>
                    {showTabComponent && (
                        <TabComponent favTabs={favTabs} isEditMode={isEditMode} />
                    )}
                </div>
                {favApps.length > 0 && (
                    <div className="tile-background">
                        <Organize favTabs={favTabs} isEditMode={isEditMode} />
                        <TileComponent
                            favApps={favApps}
                            activeTab={activeTab}
                            isEditMode={isEditMode}
                            favTabs={favTabs}
                        />
                    </div>
                )}
            </div>
        )
    );
};
export default TilePage;
