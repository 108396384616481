import { composeSearchFns, search, SearchFn } from "./search";
import { makeKnnQuery } from "./knn.search";
import { SearchContext } from "./searchContext";
import { makeKeywordsQuery } from "./keywords.search";
import { ESApiFinalResponseInterface } from "../interfaces/ElasticSearchInterface";
import { makeBoostAndPhraseQuery } from "./boostAndPhrase";

//Here we have the possible search functions.
//in the index.tsx file we should decide which one to use and pass it to a SearchFnProvider

export function boostAndPhraseSearch(
    context: SearchContext
): SearchFn<ESApiFinalResponseInterface> {
    return search(
        context,
        "boostAndPhrase",
        makeBoostAndPhraseQuery(context.boosts || [])
    );
}

export function knnSearch(
    context: SearchContext
): SearchFn<ESApiFinalResponseInterface> {
    return search(context, "knn", makeKnnQuery);
}
export function keywordsSearch(
    context: SearchContext
): SearchFn<ESApiFinalResponseInterface> {
    return search(context, "keywords", makeKeywordsQuery);
}
export function hybridSearch(
    context: SearchContext
): SearchFn<ESApiFinalResponseInterface> {
    return composeSearchFns(
        (res) => `${res.index}/${res.id}`,
        keywordsSearch(context),
        knnSearch(context),
    );
}
