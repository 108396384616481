import "./TimePicker.scss";
import {
    EonUiDropdown,
    EonUiDropdownOption,
} from "@eon-ui/eon-ui-components-react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import useRoomBookingStore from "../../store/roomBookingStore";

export function generateTimeStrings() {
    const timeStrings: string[] = [];
    for (let hour = 0; hour < 24; hour++) {
        for (let minute = 0; minute < 60; minute += 15) {
            const formattedHour = hour.toString().padStart(2, "0");
            const formattedMinute = minute.toString().padStart(2, "0");
            timeStrings.push(`${formattedHour}:${formattedMinute}`);
        }
    }
    return timeStrings;
}

function preselectNearestTime(timeStrings: string[]) {
    const currentTime = new Date();
    const currentHour = currentTime.getHours();
    const currentMinutes = currentTime.getMinutes();

    const timeString = timeStrings.find((timeString) => {
        const [hour, minute] = timeString.split(":");
        if (
            parseInt(hour) === currentHour + 1 &&
            parseInt(minute) < currentMinutes
        ) {
            return true;
        }
        return (
            parseInt(hour) === currentHour && parseInt(minute) >= currentMinutes
        );
    });

    if (!timeString) {
        throw Error(
            "There should be atleast one value matching the condition above"
        );
    }

    return timeString;
}

type TimePickerProps = {
    timeChangedHandler: (timeValue: string) => void;
};

function TimePicker({ timeChangedHandler }: TimePickerProps) {
    const { timePickerVal } = useRoomBookingStore();
    const { t } = useTranslation("room-booking");
    const timeStrings = generateTimeStrings();

    useEffect(() => {
        timeChangedHandler(preselectNearestTime(timeStrings));
    }, []);

    return (
        <>
        <EonUiDropdown
            data-testid="timePickerRoot"
            placeholder="00:00"
            onValueChanged={(e) => timeChangedHandler(e.target.value)}
            value={timePickerVal}
            name="dropdown"
            size="small"
            height="256px"
            label={t("startingTime")}
            labelOutside={true}
        >
            {timeStrings.map((item) => (
                <EonUiDropdownOption key={item} value={item} label={item} />
            ))}
        </EonUiDropdown>
        </>
        
    );
}

export default TimePicker;
