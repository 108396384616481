import {
    TabDataInterface,
    TileDataInterface,
} from "../../interfaces/TileInterface";
import { TilesContainer, RenderTileFunction } from "react-tiles-dnd";
import { EonUiButton } from "@eon-ui/eon-ui-components-react";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import "react-tiles-dnd/esm/index.css";
import "./TileComponent.scss";
import TileContent from "./TileContent";
import useSearchStore from "../../store/searchStore";
import { sortArray } from "../../util/common-util";
import useCurrentWidth from "../../util/useCurrentWidth";
interface TileComponentInterface {
    isEditMode: boolean;
    favTabs: TabDataInterface[];
    favApps: TileDataInterface[];
    activeTab: string;
}
const TileComponent: React.FC<TileComponentInterface> = ({
    isEditMode,
    favTabs,
    favApps,
    activeTab,
}) => {
    const { setPrefData, toggleFavourite, toggleEditMode, updateFavObject } =
        useSearchStore();
    const [madeChanges, setMadeChanges] = useState(false);
    const { t } = useTranslation();
    const [seqData, setSeqData] = useState<TileDataInterface[]>([]);
    const [currentTabFavApps, setCurrentTabFavApps] = useState<
        TileDataInterface[]
    >([]);
    const currentWidth = useCurrentWidth();
    const [tileWrapperWidth, setTileWrapperWidth] = useState("100%");
    const [tileWidth, setTileWidth] = useState(160);
    const [tileHeight, setTileHeight] = useState(180);

    const applyWidth = (filteredTiles: TileDataInterface[]) => {
        // -68 for ignoring padding on screen
        const availableWidth = currentWidth - 100;
        const calculateWidth = Math.floor(availableWidth / 160) * 160;
        const widthAsData = filteredTiles.length * 160;
        if (currentWidth >= 440) {
            setTileWidth(160);
            setTileHeight(180);
            if (widthAsData <= calculateWidth) {
                const width = widthAsData > 800 ? 800 : widthAsData;
                setTileWrapperWidth(`${width + 1}px`);
            } else {
                const width = calculateWidth > 800 ? 800 : calculateWidth;
                setTileWrapperWidth(`${width + 1}px`);
            }
        } else {
            setTileWidth(240);
            setTileHeight(90);
            setTileWrapperWidth("100%");
        }
    };

    useEffect(() => {
        setMadeChanges(false);
        const filteredData = favApps.filter((obj) => obj.cat === activeTab);
        const data = sortArray(filteredData, "seq");
        setSeqData(data);
        applyWidth(data);
        setCurrentTabFavApps(filteredData);
    }, [favApps, favTabs, activeTab, isEditMode]);

    useEffect(() => {
        if (seqData.length > 0) {
            applyWidth(seqData);
        }
    }, [currentWidth]);

    const onReorderTiles = (reorderedData: TileDataInterface[]) => {
        const changed =
            JSON.stringify(currentTabFavApps) !== JSON.stringify(reorderedData);
        setMadeChanges(changed);
        setSeqData(reorderedData);
    };

    const handleDragSave = (action: string) => {
        if (action === "save") {
            const currentTabSortedApps = seqData.map((obj, index) => ({
                ...obj,
                seq: index + 1,
            }));
            const newSeqdata = favApps.map((obj) => {
                if (obj.cat === activeTab) {
                    const appInSorted = currentTabSortedApps.find(
                        (item) => item.alias === obj.alias
                    );
                    return appInSorted ?? obj;
                }
                return obj;
            });
            updateFavObject({ favApps: newSeqdata, favTabs });
            setPrefData();
        } else {
            setSeqData(favApps.filter((obj) => obj.cat === activeTab));
        }
        toggleEditMode(!isEditMode);
    };

    const handleDeleteTile = (tile: TileDataInterface) => {
        toggleFavourite(tile);
        setPrefData();
    };

    const tileData: RenderTileFunction<TileDataInterface> = ({
        data,
        isDragging,
    }) => {
        return (
            <div
                className={`card ${isEditMode ? "card--movable" : ""} ${isDragging ? "card--moving" : ""}`}
            >
                <TileContent
                    isEditMode={isEditMode}
                    data={data}
                    handleDeleteTile={handleDeleteTile}
                />
            </div>
        );
    };

    return (
        <div className="tile-component">
            <div
                style={{
                    width: tileWrapperWidth,
                }}
                className="drag-wrapper"
            >
                <div
                    className={`drag-container card-component ${isEditMode ? "highlight" : ""}`}
                >
                    <TilesContainer
                        data={seqData}
                        renderTile={tileData}
                        onReorderTiles={onReorderTiles}
                        forceTileWidth={tileWidth}
                        forceTileHeight={tileHeight}
                        disabled={!isEditMode}
                    ></TilesContainer>
                </div>
            </div>
            {madeChanges && (
                <div className="common-button-wrapper">
                    <EonUiButton
                        text={t("save")}
                        rank="primary"
                        fullWidth={true}
                        size="small"
                        onClick={() => {
                            handleDragSave("save");
                        }}
                    ></EonUiButton>
                    <EonUiButton
                        text={t("cancel")}
                        rank="secondary"
                        fullWidth={true}
                        size="small"
                        onClick={() => {
                            handleDragSave("cancel");
                        }}
                    ></EonUiButton>
                </div>
            )}
        </div>
    );
};

export default TileComponent;
