import "./MostRelevantSearch.scss";
import AiIcon from "../../../assets/image/ai-icon.svg";
import recentTips from "../../../assets/image/recentTips.svg";
import { MessageContentInterface } from "../AiAssistInterface";
import { t } from "i18next";
import { EonUiLink } from "@eon-ui/eon-ui-components-react";

const MostRelevantSearch = ({
    relevantQuestion,
    relevantQuestionHandler,
}: {
    relevantQuestion: MessageContentInterface;
    relevantQuestionHandler: (question: string) => void;
}) => {
    return (
        <div className="it-support">
            <div className="bot-header">
                <div className="eon-logo">
                    <img src={AiIcon} alt="me@eon" />
                </div>
                <div className="bot-reply-container">
                    <span className="bot-heading">
                        {t(`chatBot.${relevantQuestion.messageTitle}`)}
                    </span>
                </div>
            </div>
            <div className="bot-relevant-container">
                <div className="bot-header relevant-search">
                    <div className="eon-logo-tip">
                        <img src={recentTips} alt="me@eon" />
                    </div>
                    <div className="bot-reply-container">
                        <span className="bot-heading">
                            {t("chatBot.relevantSearches")}
                        </span>
                    </div>
                </div>
                {relevantQuestion.issueList.map(
                    (question: string, index: number) => (
                        <div key={index} className="relevant-questions">
                            <EonUiLink
                                onClick={() =>
                                    relevantQuestionHandler(question)
                                }
                                key={index}
                                hideIcon={true}
                                title={t("moveTabAndTiles.organiseTilesTitle")}
                                text={question}
                                size="small"
                            ></EonUiLink>
                        </div>
                    )
                )}
                <p>{t("chatBot.aiAssitanceSuggestion")}</p>
            </div>
        </div>
    );
};

export default MostRelevantSearch;
