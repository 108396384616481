import { FeedbackPayload, LikeOrDislike, useFeedback } from "./feedback";
import { useState } from "react";
import { t } from "i18next";
import { ReactComponent as ThumbUp } from "../../assets/image/thumb_up.svg";
import { ReactComponent as ThumbDown } from "../../assets/image/thumb_down.svg";
import {
    EonUiButton,
    EonUiIcon,
    EonUiInput,
    EonUiTagPill,
} from "@eon-ui/eon-ui-components-react";

export type FeedbackData = {
    id: string;
    index: string;
};
export type FeedbackProps = {
    dataToSend: (likeDislike: LikeOrDislike) => FeedbackPayload;
    aiChatbotIndex?: number;
    createIncidentHandler?: (reason: string) => void;
};

export function Feedback({
    dataToSend,
    createIncidentHandler,
    aiChatbotIndex,
}: FeedbackProps) {
    const [likeDislike, setLikeDislike] = useState<LikeOrDislike | undefined>(
        undefined
    );

    const likeSuggestions = [
        "content",
        "tone",
        "informative",
        "complete",
        "other",
    ];
    const dislikeSuggestions = [
        "Irrelevant content",
        "Lack of detail",
        "Incorrect Information",
    ];
    const suggestions =
        likeDislike === "dislike" ? dislikeSuggestions : likeSuggestions;
    const [isFeedbackSend, setIsFeedbackSend] = useState(false);
    const [text, setText] = useState("");

    const feedback = useFeedback();
    const onClick = (likeDislike: LikeOrDislike) => () => {
        setText("");
        setIsFeedbackSend(false);
        setLikeDislike(likeDislike);
        feedback(dataToSend(likeDislike));
    };
    const handleButtonClick = (text: string) => {
        console.log("handleButtonClick");
        setIsFeedbackSend(true);
        feedback({
            source: "freeTextFromChat",
            text,
            likeDislike,
        });
    };

    return (
        <div className="feedback">
            <div className="record-feedback">
                <span className="title">{t("chatBot.feedbackTitle")}</span>
                <LikeDislike
                    like={"like"}
                    selected={likeDislike}
                    onClick={onClick("like")}
                />
                <LikeDislike
                    like={"dislike"}
                    selected={likeDislike}
                    onClick={onClick("dislike")}
                />
            </div>
            {likeDislike && (
                <>
                    {!isFeedbackSend ? (
                        <div className="feedback-form">
                            <div className="label-section">
                                {t(`chatBot.${likeDislike === "like" ? "wentWell" : "wentWrong"}`)}
                            </div>
                            <div>
                                {suggestions.map(
                                    (item: string, index: number) => (
                                        <EonUiTagPill
                                            filter-name={item}
                                            key={index}
                                            text={item}
                                            class="feedback-tag"
                                            scheme="neutral800"
                                            onClick={() => {
                                                setText(item);
                                                handleButtonClick(item);
                                            }}
                                        ></EonUiTagPill>
                                    )
                                )}
                            </div>
                            <div className="text-area-wrapper">
                                <EonUiInput
                                    className="feedback-input"
                                    value={text}
                                    type="text"
                                    placeholder={t("chatBot.feedbackPrompt")}
                                    onValueChanged={(e) =>
                                        setText(e.target.value)
                                    }
                                    size="small"
                                    onKeyDown={(evt) => {
                                        if (evt.key === "Enter") {
                                            setIsFeedbackSend(true);
                                            feedback({
                                                source: "freeTextFromChat",
                                                text,
                                                likeDislike,
                                            });
                                        }
                                    }}
                                />
                            </div>
                            <div className="label-section">
                                {t("chatBot.privacyMsg")}
                            </div>
                            <EonUiButton
                                fullWidthOnMobile
                                text={t("submit")}
                                rank="primary"
                                size="small"
                                onClick={() => {
                                    setIsFeedbackSend(true);
                                    feedback({
                                        source: "freeTextFromChat",
                                        text,
                                        likeDislike,
                                    });
                                }}
                            ></EonUiButton>
                        </div>
                    ) : (
                        <div className="feedback-response">
                            <EonUiIcon
                                className="user-icon"
                                name="radio_checkbox_tick"
                                placeholder="radio_checkbox_tick"
                            />
                            <div className="feedback-message-wrapper">
                                <p className="feedback-message">{t("chatBot.feedbackMessage")}</p>
                                {likeDislike === "dislike" && (
                                    <p className="feedback-message">{t("chatBot.appreciateService")}</p>
                                )}
                            </div>
                        </div>
                    )}
                    {aiChatbotIndex === 0 &&
                        likeDislike === "dislike" &&
                        isFeedbackSend && (
                            <div className="issue-resolver-wrapper">
                                <div>{t("chatBot.resolveIssue")}</div>
                                <div className="issue-resolver-content">
                                    <EonUiTagPill
                                        key={"incident"}
                                        text={t("chatBot.incident")}
                                        class="feedback-tag"
                                        scheme="neutral800"
                                        onClick={() => {
                                            if (createIncidentHandler) {
                                                createIncidentHandler(text);
                                            }
                                        }}
                                    ></EonUiTagPill>
                                    <span className="differentiator">
                                        {t("chatBot.differentiator")}
                                    </span>
                                    <EonUiTagPill
                                        key={"liveAgent"}
                                        text={t("chatBot.liveAgent")}
                                        class="feedback-tag"
                                        scheme="neutral800"
                                    ></EonUiTagPill>
                                </div>
                            </div>
                        )}
                </>
            )}
        </div>
    );
}

export type LikeDislikeProps = {
    like: LikeOrDislike;
    selected: LikeOrDislike | undefined;
    onClick: () => void;
};

export function LikeDislike({ like, selected, onClick }: LikeDislikeProps) {
    const IconName = like === "like" ? ThumbUp : ThumbDown;
    const isSelected = selected === like;
    return (
        <button
            onClick={() => onClick()}
            className={`thumb ${isSelected ? "thumb--selected" : ""}`}
        >
            <IconName />
        </button>
    );
}
