import {
    EonUiWebsiteNavigationColumn,
    EonUiWebsiteNavigationLogo,
    EonUiWebsiteNavigationMain,
} from "@eon-ui/eon-ui-components-react";
import "./header.scss";
import AuthComponent from "../auth/AuthComponent";
import EonLogoSVG from "../../assets/image/logo-eon-red.svg";
import bayernwerkLogo from "../../assets/image/bayernwerk-logo.svg";
import MeEonLogo from "../../assets/image/me8eon-Logo.svg";
import MeEonBayernLogo from "../../assets/image/MeEonBayern-Logo.svg";
import LanguageSwitch from "../../components/language-switch/LanguageSwitch";
import { useCombinedStore } from "../../store/headerStore";

const Header = ({ orgName }: { orgName: string }) => {
    const { language, setLanguage, favData } = useCombinedStore();
    console.log({ orgName, language, setLanguage, favData });

    return (
        <EonUiWebsiteNavigationMain
            slot="main"
            className="me-eon-header"
            data-testid="header"
        >
            <EonUiWebsiteNavigationColumn content-alignment="left">
                <EonUiWebsiteNavigationLogo
                    image-src={
                        orgName === "bayernwerk" ? MeEonBayernLogo : MeEonLogo
                    }
                    description="Me@E.ON"
                    href="/tile"
                ></EonUiWebsiteNavigationLogo>
            </EonUiWebsiteNavigationColumn>
            <EonUiWebsiteNavigationColumn content-alignment="right">
                <AuthComponent />
                <LanguageSwitch />
                <div className="eon-logo-wrapper">
                    <EonUiWebsiteNavigationLogo
                        image-src={
                            orgName === "bayernwerk"
                                ? bayernwerkLogo
                                : EonLogoSVG
                        }
                        description="E.ON Logo"
                    ></EonUiWebsiteNavigationLogo>
                </div>
            </EonUiWebsiteNavigationColumn>
        </EonUiWebsiteNavigationMain>
    );
};
export default Header;
