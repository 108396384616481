import React from "react";
import { AxiosStatic } from "axios";
import { useSessionId } from "./sessionId";
import { TimeService, useTime } from "../../domain/useTime";
import EnvironmentConfig from "../../config/EnvironmentConfig";
import "./feedback.scss";

export type LikeOrDislike = "like" | "dislike";
// export type FeedbackSource = 'chatbot' | 'search'

export type QueryFeedbackPayload = {
    source: "query";
    page: "simple" | "advanced";
    query: string;
};
export type ClickFeedbackPayload = {
    source: "click";
    href: string;
    index: string;
    id: string;
};
export type SearchFeedbackPayload = {
    source: "search";
    query: string;
    index: string;
    id: string;
    like: LikeOrDislike | undefined;
};
export type ChatbotQueryFeedbackPayload = {
    source: "chatquery";
    query: string;
};
export type ChatbotFeedbackPayload = {
    source: "chatbot";
    messages: any[];
    query: string;
    like: LikeOrDislike;
};
export type FreeTextPayload = {
    source: "freeTextFromChat";
    text: string;
    likeDislike?: LikeOrDislike;
};

export type FeedbackPayload =
    | SearchFeedbackPayload
    | ChatbotFeedbackPayload
    | QueryFeedbackPayload
    | ClickFeedbackPayload
    | ChatbotQueryFeedbackPayload
    | FreeTextPayload;

export type FeedbackFn = (p: FeedbackPayload) => Promise<void>;
export type RawFeedbackFn = (
    sessionId: string,
    timeService: TimeService
) => FeedbackFn;

export const FeedbackContext = React.createContext<RawFeedbackFn | undefined>(
    undefined
);
export type FeedbackProviderProps = {
    feedbackFn: RawFeedbackFn;
    children: React.ReactNode;
};
export const FeedbackProvider: React.FC<FeedbackProviderProps> = ({
    feedbackFn,
    children,
}) => (
    <FeedbackContext.Provider value={feedbackFn}>
        {children}
    </FeedbackContext.Provider>
);

export const useFeedback = (): FeedbackFn => {
    const sessionId = useSessionId();
    const time = useTime();
    const feedback = React.useContext(FeedbackContext);
    if (feedback === undefined) throw new Error("FeedbackContext not found");
    return feedback(sessionId, time);
};

export const defaultFeedbackFn =
    (axios: AxiosStatic, index: string): RawFeedbackFn =>
    (sessionId: string, timeService: TimeService): FeedbackFn =>
    async (p: FeedbackPayload) => {
        const payload: any = {
            ...p,
            sessionId,
            timestamp: timeService().getTime(),
        };
        return axios.post(`${EnvironmentConfig.elasticSearchBaseUrl}/${index}/_doc`, payload, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `ApiKey ${localStorage.getItem("dls-apikey")}`,
            },
        });
    };
