import axios from "axios";
import AzureAiClientBase, {TAzureAiClientConstructor} from "./ai.base.client";
import {Message} from "../ai.types";
import {AiSearchResult, MakePromptFn, promptsByIndex} from "../ai.prompts";
import {SearchFn} from "../../../search/search";
import {ESApiFinalResponseInterface} from "../../../interfaces/ElasticSearchInterface";
import {getFrom} from "../../../util/map";
import {OutputAndSource} from "../ai.rag.client";
import {defaultSearchContext} from "../../../search/searchContext";
import {knnSearch} from "../../../search/all.searchs";

type AiRagConfig = {
    searchFn: SearchFn<ESApiFinalResponseInterface>;
    searchIndicies: string[];
    selectSearchResult: (
        searchResults: ESApiFinalResponseInterface[]
    ) => ESApiFinalResponseInterface;
    makePrompt: MakePromptFn;
    makeCustomPrompt: (history: Message[], query: string, role: Message["role"]) => Message[]
    makeOutputAndSource: (
        searchResultId: string | undefined,
        messages: Message[]
    ) => OutputAndSource;
};

export function defaultAiRagConfig(
    searchIndicies: string[],
    searchFn: SearchFn<ESApiFinalResponseInterface>
): AiRagConfig {
    return {
        searchIndicies,
        searchFn,
        selectSearchResult: (searchResults: ESApiFinalResponseInterface[]) =>
            searchResults.sort(questionAndKnnFirstComparator)[0],
        makePrompt: (
            history: Message[],
            searchResult: AiSearchResult,
            query: string,
            language: string
        ): Message[] => {
            const basePrompt = getFrom(promptsByIndex)(searchResult?.index)(
                query,
                searchResult,
                language

            );
            return [
                {role: "system", content: basePrompt},
                ...history,
                {role: "user", content: query},
            ];
        },
        makeCustomPrompt: (history: Message[], query: string, role: Message["role"]) => [
            ...history,
            {role, content: query}],

        makeOutputAndSource: (
            searchResultId: string | undefined,
            messages: Message[]
        ): OutputAndSource => ({
            output: messages[0].content,
            source: searchResultId || "No search result found",
        }),
    };
}


export function questionAndKnnFirstComparator(a: any, b: any): number {
    if ("content" in a && !("content" in b)) {
        return 1;
    } else if (!("content" in a) && "content" in b) {
        return -1;
    } else {
        if (a?.queryType === "knn" && b?.queryType !== "knn") return -1;
        if (a?.queryType !== "knn" && b?.queryType === "knn") return 1;
        return 0;
    }
}


export class AiRagClient extends AzureAiClientBase {
    protected elasticApiKey: string | undefined;
    private readonly searchContext: ReturnType<typeof defaultSearchContext>;
    private aiRagConfig: AiRagConfig;

    constructor(config: TAzureAiClientConstructor, elasticApiKey?: string) {
        super(config);
        this.elasticApiKey = elasticApiKey;
        this.searchContext = defaultSearchContext(axios, this.elasticApiKey);
        this.aiRagConfig = {
            ...defaultAiRagConfig(
                ["office-buddy-prod", "questionator-prod", "apps-prod"],
                knnSearch(this.searchContext)
            ),
        };
    }

    public async aiClientWithRag(history: Message[], query: string, language: string) {
        const searchResults = await this.aiRagConfig.searchFn({
            searchTerm: query,
            searchIndexes: this.aiRagConfig.searchIndicies,
        });
        const searchResult = this.aiRagConfig.selectSearchResult(searchResults);
        const prompt: Message[] = this.aiRagConfig.makePrompt(
            history,
            searchResult as any as AiSearchResult,
            query,
            language
        );
        const outputMessages = await this.sendRequest(prompt);
        return this.aiRagConfig.makeOutputAndSource(searchResult?.id, outputMessages);
    }
}