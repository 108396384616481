import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const CustomRedirect: React.FC = () => {
    const navigate = useNavigate();

    useEffect(() => {
        window.location.href = `/roombooking${window.location.search}`;
    }, [navigate]);

    return null;
};

export default CustomRedirect;
