import { emptyDataObject, SourceInfo } from "../info";

export const gitlabInfo: SourceInfo = {
    match: "gitlab",
    dropDown: {
        label: "Gitlab",
        value: "gitlab-prod",
        icon: "gitlab",
    },
    titleAndTarget: (result) => ({
        title: result?.fullPath,
        target: result.url,
        source: "Gitlab",
    }),
    makeDataObject: (result) => ({
        ...emptyDataObject,
        id: result.id,
        index: result.index,
        icon: "gitlab",
        link: result.url,
        name: result.fullPath,
        source: "Gitlab",
        desc: result.content,
        status: result.status,
        createdBy: result.created_by,
        render: "markdown",
    }),
};
