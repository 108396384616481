import { ReactNode, useEffect } from "react";
import ReactDOM from "react-dom";
import "./Modal.scss";
import FocusTrap from "../focus-trap/FocusTrap";

interface ModalProps {
    children: ReactNode;
    toggleModal: boolean;
    closeModal: () => void;
    isCloseIcon: boolean;
    modalClass?: string;
    closeOnBackdrop?: boolean;
}

export default function Modal({
    children,
    toggleModal,
    closeModal,
    isCloseIcon,
    modalClass,
    closeOnBackdrop,
}: ModalProps) {
    document.getElementsByTagName("body")[0].style.overflow = toggleModal
        ? "hidden"
        : "auto";
    if (!toggleModal) return null;

    useEffect(() => {
        const elem = document.getElementById("startFocusHere");
        if (elem) {
            elem?.remove();
        }
    }, []);

    return ReactDOM.createPortal(
        <FocusTrap>
            <div
                className={`feh-modal ${modalClass}`}
                data-role="dialog"
                onClick={() => {
                    if (closeOnBackdrop) {
                        closeModal();
                    }
                }}
            >
                <div
                    className="feh-modal-container"
                    onClick={(e) => e.stopPropagation()}
                >
                    <input id="startFocusHere" autoFocus />
                    {isCloseIcon ? (
                        <div className="close" aria-hidden="true">
                            <button
                                className="close-icon-button"
                                onClick={closeModal}
                            >
                                &times;
                            </button>
                        </div>
                    ) : null}
                    {children}
                </div>
            </div>
        </FocusTrap>,
        document.getElementById("modal-content-component")!
    );
}
