import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./ManageBooking.scss";
import TableComponent, {
    TableAction,
} from "../../generic-components/data-table/TableComponent";
import { getAccessToken } from "../../../services/accessToken";
import {
    BookingData,
    ManageBookingData,
    // MEETING_TYPES,
    MEETING_ROOM_TYPES,
} from "../RoomBookingInterface";
import EditBooking from "../edit-booking/EditBooking";
import CancelBooking from "../cancel-booking/CancelBooking";
import tableHeaderLabel from "./tableHeaderLabel.json";
import SearchBooking from "../search-booking/SearchBooking";
import Modal from "../../modal/Modal";
import useRoomBookingStore from "../../../store/roomBookingStore";
import { getUserBookingApi } from "../../../services/roomBooking";
import { formatDate, getFormattedDateTime } from "../../../util/date";

type tableDataType = {
    [key: string]: string;
};
type tableDataLang = {
    [key: string]: tableDataType;
};

function ManageBooking() {
    const { t, i18n } = useTranslation("room-booking");
    const [cols, setCols] = useState<string[]>([]);
    const [headerMaps, setHeaderMaps] = useState<string[]>([]);
    const [originalResData, setOriginalResData] = useState<ManageBookingData[]>(
        []
    );
    const [filteredResData, setFilteredResData] = useState<ManageBookingData[]>(
        []
    );
    const tableHeaderData: tableDataLang = tableHeaderLabel;
    const UPCOMING_BOOKING_DAYS = 15;
    const lang = i18n.language;

    const {
        cancelBookingModal,
        setCancelBookingModal,
        editBookingModal,
        setEditBookingModal,
        setCurrentBookingData,
        setManageBookingRawData,
        searchType,
        searchQuery,
    } = useRoomBookingStore();

    useEffect(() => {
        getUserBookings();
    }, []);

    useEffect(() => {
        if (searchQuery.length || searchType !== "All") {
            setFilteredResData(
                originalResData.filter(
                    (meeting: ManageBookingData) =>
                        (meeting.meeting_id
                            .toString()
                            .toLowerCase()
                            .includes(searchQuery.toLowerCase()) ||
                            meeting.meeting_desc
                                .toLowerCase()
                                .includes(searchQuery.toLowerCase()) ||
                            meeting.meeting_location
                                .toLowerCase()
                                .includes(searchQuery.toLowerCase())) &&
                        (searchType !== "All"
                            ? meeting.meeting_type.toLowerCase() ===
                              searchType.toLowerCase()
                            : true)
                )
            );
        } else {
            setFilteredResData(originalResData);
        }
    }, [searchQuery, searchType, originalResData]);

    useEffect(() => {
        if (filteredResData.length) {
            setCols(
                Object.keys(filteredResData[0]).map(
                    (key) => tableHeaderData[i18n.language][key]
                )
            );
            setHeaderMaps(Object.keys(filteredResData[0]).map((key) => key));
        }
    }, [filteredResData, lang]);

    const getUserBookings = async () => {
        const actualToken = await getAccessToken();
        const payload = {
            timeframe: {
                begin: {
                    datetime: getFormattedDateTime(),
                },
                end: {
                    datetime: getFormattedDateTime(
                        new Date().getTime() +
                            UPCOMING_BOOKING_DAYS * 86400 * 1000
                    ),
                },
            },
            accesstoken: actualToken || "",
            dummytoken: "",
            include: ["booking_container", "object"],
        };
        try {
            const response = await getUserBookingApi(payload);
            const bookings = response.data;
            if (response.status == "success") {
                setManageBookingRawData(bookings);
                setOriginalResData(formatManageBookingData(bookings));
                setFilteredResData(formatManageBookingData(bookings));
            } else {
                console.log("something went wrong!", response);
            }
        } catch (e) {
            console.log("something went wrong!", e);
        }
    };

    const actions: TableAction[] = [
        {
            icon: "edit",
            onClick: (data: ManageBookingData) => {
                setCurrentBookingData(data);
                setEditBookingModal(true);
            },
        },
        {
            icon: "trash_bin",
            onClick: (data: ManageBookingData) => {
                setCurrentBookingData(data);
                setCancelBookingModal(true);
            },
        },
    ];

    const formatManageBookingData = (resData: BookingData[]) => {
        const manageBookingData: ManageBookingData[] = [];
        resData.forEach((value: BookingData) => {
            manageBookingData.push({
                meeting_id: value.booking_id,
                meeting_on: formatDate(value.begin.timestamp),
                meeting_timing: `${getFormattedDateTime(
                    value.begin.timestamp * 1000,
                    "H:i",
                    true
                )} - ${getFormattedDateTime(
                    value.end.timestamp * 1000,
                    "H:i",
                    true
                )}`,
                meeting_desc: value.topic,
                meeting_location: value.object_name,
                meeting_type: MEETING_ROOM_TYPES.find(type => type.id === value.object_type_id)?.name ?? "",
                meeting_participants: value.capacity,
                actions: actions,
            });
        });

        return manageBookingData;
    };

    const reloadManageBookingList = () => {
        getUserBookings();
    };

    return (
        originalResData.length ? (
            <div className="child-container">
                <div className="page-sub-title">{t("manageBookings")}</div>
                <hr />

                <SearchBooking />
        
                <TableComponent
                rows={filteredResData}
                cols={cols}
                headerMaps={headerMaps}
                showPagination={false}
                />
        
                <Modal
                toggleModal={editBookingModal}
                closeModal={() => setEditBookingModal(!editBookingModal)}
                isCloseIcon={false}
                closeOnBackdrop={false}
                >
                <EditBooking reloadManageBookingList={reloadManageBookingList} />
                </Modal>
        
                <Modal
                toggleModal={cancelBookingModal}
                closeModal={() => setCancelBookingModal(!cancelBookingModal)}
                isCloseIcon={false}
                closeOnBackdrop={false}
                >
                <CancelBooking reloadManageBookingList={reloadManageBookingList} />
                </Modal>
            </div>
        ) : (
            <div className="no-booking-title">{t("noBooking")}</div>
        )
    );
}

export default ManageBooking;
