import { DataObjAsProps } from "./ElSearchAdvanceCard";
import { CSSProperties } from "react";
import Markdown from "react-markdown";
import DOMPurify from "dompurify";

export function row3(children: JSX.Element, extras?: CSSProperties) {
    const style: CSSProperties = {
        display: "-webkit-box",
        WebkitLineClamp: 5,
        WebkitBoxOrient: "vertical",
        overflow: "hidden",
        textOverflow: "ellipsis",
        ...extras,
    };
    return (
        <div style={children && style} className="row-3">
            {children}
        </div>
    );
}

const captureText = (htmlString: string, matchingText?: string) => {
    const parser = new DOMParser();
    const document = parser.parseFromString(htmlString, "text/html");

    // Helper function to recursively extract text with spaces
    const extractText = (node: Node): string => {
        let text = "";
        node.childNodes.forEach((childNode) => {
            if (childNode.nodeType === Node.TEXT_NODE) {
                text += childNode.textContent + " ";
            } else if (childNode.nodeType === Node.ELEMENT_NODE) {
                text += extractText(childNode) + " ";
            }
        });
        return text.trim();
    };

    // Extract text from the body
    const bodyText = extractText(document.getElementsByTagName("body")[0]);

    // Limit the extracted text to 1000 characters
    const limitedText = bodyText.slice(0, 1000).trim();

    if (matchingText && matchingText.length > 3) {
        const matchingTextArr = matchingText.trim().split(" ");
        const updatedData = matchingTextArr.reduce((text, word) => {
            const alphaNumRegex = /^[a-zA-Z0-9]+$/;

            if (alphaNumRegex.test(word)) {
                const regex = new RegExp(`(${word})`, "gi");
                return text.replace(
                    regex,
                    "<strong style='background-color: #e3e000'>$1</strong>"
                );
            }
            return text;
        }, limitedText);
        return updatedData;
    }

    return limitedText;
};

export function SafeHtml({
    html,
    searchTerm,
}: {
    html: string;
    searchTerm?: string;
}) {
    const style: CSSProperties = {
        maxHeight: "45px",
        overflow: "hidden",
        display: "block",
        textOverflow: "ellipsis",
    };
    const sanitizedHTML = DOMPurify.sanitize(html);
    const styledHTML = captureText(sanitizedHTML, searchTerm);
    if (!styledHTML) {
        return null;
    }
    return (
        <div
            style={style}
            className="row-3"
            dangerouslySetInnerHTML={{ __html: styledHTML }}
        />
    );
}
export function Description({ dataObj, searchTerm }: DataObjAsProps) {
    const desc = dataObj.desc;
    const indent = {
        // borderLeft: "2px solid #d3d3d3",
        // paddingLeft: "10px",
        // marginLeft: "30px",
    };
    if (desc === undefined || desc.length === 0) return null;
    if (dataObj.render === "markdown")
        return row3(<Markdown>{desc}</Markdown>, indent);
    if (dataObj.render === "html")
        return row3(<SafeHtml html={desc} searchTerm={searchTerm} />, indent);
    if (dataObj.type === "Image") {
        return row3(
            <div className="image-content">
                <img src={dataObj.link} loading="lazy" alt={dataObj.link} />
            </div>
        );
    }
    return row3(<>{desc}</>);
}
