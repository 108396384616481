import React, { useEffect, useState } from "react";
import { FilterSetters } from "./elsearch.domain";
import { SelectFilterButton } from "./SelectFilterButton";
import {
    EonUiAccordion,
    EonUiAccordionPane,
    EonUiIcon,
} from "@eon-ui/eon-ui-components-react";
import { useTranslation } from "react-i18next";
import EnvironmentConfig from "../../config/EnvironmentConfig";
import axios from "axios";
import { getReqBody } from "../../search/boostAndPhrase";
import "./SearchFiltersRight.scss";
import DataSourceFilters from "./DataSourceFilters";
interface SearchFiltersInterface {
    setters: FilterSetters;
    searchQuery: string;
    searchIndex?: string[];
}

const SearchFiltersRight: React.FC<SearchFiltersInterface> = ({
    setters,
    searchQuery,
    searchIndex,
}) => {
    const [dataSourceFilters, setDataSourceFilters] = useState([]);

    useEffect(() => {
        const getFilterCount = async () => {
            const searchUrl = `${EnvironmentConfig.elasticSearchBaseUrl}/${EnvironmentConfig.indicies}/_search`;

            const response = await axios.post(
                searchUrl,
                getReqBody(searchQuery, [], [], 0),
                {
                    headers: {
                        Authorization: `ApiKey ${localStorage.getItem(
                            "dls-apikey"
                        )}`,
                    },
                }
            );
            const filters = response?.data?.aggregations?.count?.buckets || [];
            setDataSourceFilters(filters);
        };
        getFilterCount();
    }, [searchQuery]);

    const { t } = useTranslation();

    if (dataSourceFilters.length === 0) {
        return null;
    }

    return (
        <div className="search-filter-wrapper">
            <div className="search-filter-header">
                <div className="filter-header-left">
                    <EonUiIcon
                        placeholder="funnel-img"
                        size="normal"
                        key={"funnel-icon"}
                        name="funnel"
                    ></EonUiIcon>
                    {t("filterPage.filterMenu.filterHeading")}
                </div>
                <SelectFilterButton
                    isReset={true}
                    setters={setters}
                    textkey="filterPage.filterMenu.reset"
                    dataType="all"
                    value={[]}
                    clearActiveFilter={()=>setDataSourceFilters([...dataSourceFilters])}
                />
            </div>
            {dataSourceFilters.length > 0 && (
                <EonUiAccordion mode="multiple" selectedPaneIndex="0">
                    <EonUiAccordionPane pane-title="Data Sources" slot="Pane1">
                        <DataSourceFilters
                            setters={setters}
                            dataSourceFilters={dataSourceFilters}
                            searchIndex={searchIndex}
                        />
                    </EonUiAccordionPane>
                </EonUiAccordion>
            )}
        </div>
    );
};
export default SearchFiltersRight;
