import "./LocationDropdown.scss";
import { useTranslation } from "react-i18next";
import RoomLocations from "./RoomLocations";
import useRoomBookingStore from "../../store/roomBookingStore";

function LocationDropdown() {
    const { t } = useTranslation("room-booking");
    const clickedLocation = (
        ids: number[],
        name: string,
        closeDropDown?: boolean
    ) => {
        setCurrentLocationIds(ids);
        setClickedFloor(name);
        if (closeDropDown) {
            setLocationDropdownOpen(false);
        }
    };
    const {
        clickedFloor,
        setClickedFloor,
        setLocationDropdownOpen,
        setLocationDropdownError,
        locationDropdownError,
        isLocationDropdownOpen,
        setCurrentLocationIds,
        userData
    } = useRoomBookingStore();

    return (
        <div className="location-dropdown">
            <label className="form-label">
                {t("selectRoomLocation")}
            </label>
            <div
                className={`selected-location${
                    isLocationDropdownOpen ? " location-dropdown-open" : ""
                }${locationDropdownError ? " location-dropdown-error" : ""}${
                    !clickedFloor?.length
                        ? " location-dropdown-placeholder"
                        : ""
                }`}
                onClick={(event) => {
                    event.stopPropagation();
                    setLocationDropdownError("");
                    setLocationDropdownOpen(true);
                }}
            >
                {clickedFloor?.trim().length
                    ? clickedFloor
                    : "Select Location / Room"}
            </div>
            <div className="selected-location-error">
                {locationDropdownError}
            </div>
            <RoomLocations
                className={`${
                    isLocationDropdownOpen
                        ? "location-dropdown-show"
                        : "location-dropdown-hide"
                }`}
                clickedLocation={(
                    ids: number[],
                    name: string,
                    closeDropDown?: boolean
                ) => {
                    clickedLocation(ids, name, closeDropDown);
                    if (name) {
                        setLocationDropdownError("");
                    }
                }}
                home_location_id={userData.home_location_id}
                clickedFloor={clickedFloor}
            />
        </div>
    );
}

export default LocationDropdown;
