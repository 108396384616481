import { useEffect, useState } from "react";
import {
    EonUiButton,
    EonUiIcon,
    EonUiInput,
    EonUiLink,
    EonUiPreloaderSquares,
    EonUiTagPill,
    EonUiTagPillGroup,
} from "@eon-ui/eon-ui-components-react";
import "./AiAssist.scss";
import AiAssistData from "./AiAssistData";
import TypingEffect from "./typing-effect/TypingEffect";
import Card from "./ai-assist-card/AiAssistCard";
import { t } from "i18next";
import { AiAssistMessageInterface } from "./AiAssistInterface";
import {
    ChatbotFeedbackPayload,
    LikeOrDislike,
    useFeedback,
} from "../feedback/feedback";
import { Feedback } from "../feedback/feedback.component";
import AiIcon from "../../assets/image/ai-icon.svg";
import AIChatBot from "../../assets/image/aichatbot.svg";
import UserIcon from "../../assets/image/user.svg";
import { useAuth } from "../../MsalContext";
import SearchList from "../search-list";
import { AiClient, useAiWithRag } from "../ai/ai.rag.client";
import MostRelevantSearch from "./most-relevant-search/MostRelevantSearch";
import { raiseIncident } from "../../services/raiseIncident";
import { Message } from "../ai/ai.types";
import { useTranslation } from "react-i18next";

const getRole = (messageType: string) => {
    switch (messageType) {
        case "user-reply":
            return "user";
        case "bot-reply":
            return "assistant";
        case "create-incident":
            return "system";
        default:
            return "assistant";
    }
};

const AiAssist = ({
    setShowAiAssist,
}: {
    setShowAiAssist: (value: boolean) => void;
}) => {
    const [messages, setMessages] = useState<any[]>([]);
    const [inputVal, setInputVal] = useState("");
    const [query, setQuery] = useState<string | null>(null);
    const [lastQuery, setLastQuery] = useState<string>("");
    const [respLoading, setRespLoading] = useState(false);
    const [disableInput, setDisableInput] = useState(false);
    const [isRelevaltSearch, setIsRelevaltSearch] = useState(false);
    const feedbackFn = useFeedback();
    const ai: AiClient = useAiWithRag();
    const { account } = useAuth();
    const nameArray = account?.name?.split(",") ?? [];
    const firstName = nameArray[1] || nameArray[0];
    const [aiChatbotIndex, setAiChatBotIndex] = useState(0);
    const [isCreateIncident, setIsCreateIncident] = useState(false);
    const [isEditIncident, setIsEditIncident] = useState(false);
    const [confirmTicketCreation, setConfirmTicketCreation] = useState(false); // can be removed #todo
    const [incidentResponse, setIncidentResponse] = useState<any>(); //#todo remove any depending upon the respone
    const [feedbackReason, setFeedbackReason] = useState("");
    const [newChatStarted, setNewChatStarted] = useState(false);
    const language = useTranslation().i18n.language;
    const [cancelIncidentCreation, setCancelIncidentCreation] = useState(false);
    const [queRepeatError, setQueRepeatError] = useState(false);

    const handleSendMessage = async () => {
        const userRply = {
            messageContent: inputVal,
            messageType: "user-reply",
        };
        // setAiChatBotIndex(-1); // to hide relevant questions
        setMessages((prevMessages) => [...prevMessages, userRply]);
        setLastQuery(inputVal);
        feedbackFn({ source: "chatquery", query: inputVal });
        setInputVal("");
        setDisableInput(true);
        setRespLoading(true);
        setQueRepeatError(false);

        const normalizedMessageHistory: Message[] = [...messages].map(
            (message) => ({
                content: message.messageContent as string,
                role: getRole(message.messageType),
            })
        );

        const lastItem: Message[] = normalizedMessageHistory.slice(
            normalizedMessageHistory.length - 2
        );

        const resp = isCreateIncident
            ? await ai.aiClientSummariseChatHistoryToIncident(
                  lastItem,
                  feedbackReason
              )
            : await ai.aiClientWithRag(
                  normalizedMessageHistory,
                  inputVal,
                  language
              );

        if (resp) {
            setRespLoading(false);
            let messageContent;
            if (resp.output) {
                messageContent = `${parseLinks(resp.output)} ${
                    resp.source
                        ? `<br /><b>Source: Smarthub KB - ${resp.source}</b>`
                        : ""
                }`;
            } else {
                messageContent =
                    "This service is not available at the moment, please try again later.";
            }
            const botReply = {
                messageContent,
                messageType: isCreateIncident ? "create-incident" : "bot-reply",
                index: messages.length + 1,
                showDisclaimer: "none",
            };
            if (!isCreateIncident) {
                resetCreateIncidentAttr();
            }
            setMessages((prevMessages) => [...prevMessages, botReply]);
        }
    };
    const effecttEnd = () => {
        setDisableInput(false);
        showDisclaimerForCurrentMsg();
    };
    const showDisclaimerForCurrentMsg = () => {
        messages[messages.length - 1].showDisclaimer = "block";
    };

    useEffect(() => {
        if (newChatStarted) {
            resetCreateIncidentAttr();
            setMessages([]);
            setRespLoading(false);
            setDisableInput(false);
            setIsRelevaltSearch(false);
        }
    }, [newChatStarted]);

    const resetCreateIncidentAttr = () => {
        setAiChatBotIndex(0);
        setIsCreateIncident(false);
        setIsEditIncident(false);
        setConfirmTicketCreation(false);
        setFeedbackReason("");
        setCancelIncidentCreation(false);
    };

    const setInputQueryFromQuestion = (clickedQue: string) => {
        if (clickedQue === query) {
            setQueRepeatError(true);
        }
        setInputVal(clickedQue);
        setQuery(clickedQue);
        setIsCreateIncident(false);
    };

    const relevantQuestionHandler = (question: string) => {
        setInputVal(question);
        setQuery(question);
        setIsRelevaltSearch(true);
        setIsCreateIncident(false);
    };

    const createIncidentFromChatBot = (reason: string) => {
        setInputVal("Create an Incident");
        setQuery("Create an Incident");
        setFeedbackReason(reason);
        setIsCreateIncident(true);
        setCancelIncidentCreation(false);
    };

    const cancelIncident = () => {
        if (isEditIncident) {
            setIsCreateIncident(false);
            setIsEditIncident(false);
            setConfirmTicketCreation(false);
        } else {
            setConfirmTicketCreation(true);
        }
        setCancelIncidentCreation(true);
    };

    const createIncidentApi = async (message: string) => {
        // API can for create incident
        setRespLoading(true);
        setIsEditIncident(false);
        try {
            const incidentResponse = await raiseIncident(message);
            setIncidentResponse(incidentResponse);
            setConfirmTicketCreation(true);
            setRespLoading(false);
        } catch (popupError) {
            setRespLoading(false);
            console.error(
                "Something went wrong. Please try again later.",
                popupError
            );
        }
    };

    const editIncidentResponse = () => {
        setIsEditIncident(true);
        setConfirmTicketCreation(false);
    };

    useEffect(() => {
        if (query !== null) {
            handleSendMessage();
        }
    }, [query]);

    const parseLinks = (str: string) => {
        // Replace markdown-style links with HTML <a> tags
        const withHtmlLinks = str.replace(
            /\[(.*?)\]\((.*?)\)/g,
            '<a target="_blank" href="$2">$1</a>'
        );

        // Replace bare URLs with HTML <a> tags
        // This regex will not try to exclude URLs already inside an href attribute,
        // but it's more widely supported.
        return withHtmlLinks.replace(/(https?:\/\/[^\s]+)/g, (match, p1) => {
            // To avoid double-wrapping URLs already in <a> tags,
            // we can check if they are already wrapped in <a>
            if (
                /href="/.test(
                    withHtmlLinks.slice(
                        withHtmlLinks.indexOf(match) - 10,
                        withHtmlLinks.indexOf(match) + 10
                    )
                )
            ) {
                return match; // Return the match as-is if already within an href attribute
            }
            return `<a target="_blank" href="${p1}">${p1}</a>`;
        });
    };
    const renderChatContent = (
        message: AiAssistMessageInterface,
        index: number
    ) => {
        let htmlList: JSX.Element = <></>;
        switch (message.messageType) {
            case "bot-reply":
                htmlList = (
                    <div key={index} className="bot-header">
                        <div className="eon-logo">
                            <img src={AiIcon} alt="me@eon" />
                        </div>
                        <div className="bot-reply-container">
                            <span className="bot-heading">AI Assistant</span>
                            <TypingEffect
                                effecttEnd={effecttEnd}
                                className="bot-text"
                                text={message.messageContent}
                                speed={1}
                            />
                            {/* <span
                                style={{ display: message.showDisclaimer }}
                                className="disclaimer-text"
                            >
                                {t("chatBot.disclaimerText")}
                            </span> */}
                        </div>
                    </div>
                );
                break;
            case "create-incident":
                htmlList = (
                    <div key={index} className="bot-header">
                        <div className="eon-logo">
                            <img src={AiIcon} alt="me@eon" />
                        </div>
                        <div className="bot-reply-container">
                            <span className="bot-heading">
                                Creating an Incident
                            </span>
                            <div className="ticket-container">
                                <div className="ticket-description">
                                    <span className="">
                                        {t("chatBot.ticketDescription")}
                                    </span>
                                </div>
                                <div className="message-content">
                                    {isEditIncident ? (
                                        <EonUiInput
                                            value={message.messageContent}
                                            size="normal"
                                            rows={5}
                                            textarea
                                            onValueChanged={(e) =>
                                                (messages[
                                                    index
                                                ].messageContent =
                                                    e.target.value)
                                            }
                                        />
                                    ) : (
                                        <div>{message.messageContent}</div>
                                    )}
                                </div>
                                {!confirmTicketCreation && (
                                    <div
                                        key={index}
                                        className="buttons-section"
                                    >
                                        <EonUiTagPillGroup>
                                            <EonUiTagPill
                                                text={
                                                    isEditIncident
                                                        ? t(
                                                              "chatBot.createIncident"
                                                          )
                                                        : t(
                                                              "chatBot.confirmCreateIncident"
                                                          )
                                                }
                                                key={`${index}`}
                                                class="feedback-tag"
                                                scheme="neutral800"
                                                onClick={() =>
                                                    createIncidentApi(
                                                        message.messageContent
                                                    )
                                                }
                                            ></EonUiTagPill>
                                            {!isEditIncident && (
                                                <EonUiTagPill
                                                    text={t(
                                                        "chatBot.editCreateIncident"
                                                    )}
                                                    onClick={
                                                        editIncidentResponse
                                                    }
                                                    key={`${index}${isEditIncident}`}
                                                    scheme="neutral800"
                                                ></EonUiTagPill>
                                            )}
                                            <EonUiTagPill
                                                text={t("cancel")}
                                                key={`${index}`}
                                                scheme="red500"
                                                onClick={cancelIncident}
                                            ></EonUiTagPill>
                                        </EonUiTagPillGroup>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                );
                break;
            case "user-reply":
                htmlList = (
                    <div key={index} className="user-header">
                        <div className="user-logo">
                            <img src={UserIcon} alt="userIcon" />
                        </div>
                        <div className="user-reply-container">
                            <span className="user-heading">{firstName}</span>
                            <p className="bot-text">{message.messageContent}</p>
                        </div>
                    </div>
                );
                break;
            default:
                break;
        }
        return htmlList;
    };

    function dataToSend(like: LikeOrDislike) {
        const payload: ChatbotFeedbackPayload = {
            like: like,
            source: "chatbot",
            messages: messages,
            query: lastQuery,
        };
        return payload;
    }

    const newAiChat = () => {
        setNewChatStarted(!newChatStarted);
    };

    return (
        <>
            <div className="search-wrapper">
                <EonUiLink
                    className="back-button"
                    icon="search"
                    iconPosition="left"
                    placeholder="back"
                    onClick={() => setShowAiAssist(false)}
                    text={t("chatBot.searchBack")}
                    title={t("tilePage.backToSearch")}
                    href="javascript:void(0)"
                ></EonUiLink>
                <button
                    className="new-ai-chat"
                    onClick={() => newAiChat()}
                    title={t("chatBot.newChat")}
                >
                    <span className="icon-wrapper">
                        <EonUiIcon
                            placeholder="add"
                            className="margin-add"
                            size="small"
                            name={"add"}
                            scheme="red500"
                        ></EonUiIcon>
                    </span>
                    <span className="add-new-ai-chat">
                        {t("chatBot.newChat")}
                    </span>
                </button>
            </div>
            <div className="ai-assist-wrapper">
                <div className="ai-assit-container">
                    {messages.length <= 0 && (
                        <>
                            <div className="ai-assist-welcome">
                                <div>
                                    <img src={AIChatBot} alt="me@eon" />
                                </div>
                                <div>
                                    <span
                                        title={t("chatBot.topicStartMessage")}
                                        key={t("chatBot.topicStartMessage")}
                                        className="start-message"
                                    >
                                        {t("chatBot.hey")} {firstName},{" "}
                                        {t("chatBot.topicStartMessage")}
                                    </span>
                                </div>
                            </div>
                            <div className="card-wrapper">
                                <Card
                                    messages={AiAssistData.welcome}
                                    aiChatBotHandler={(index) => {
                                        setAiChatBotIndex(index);
                                        // isNewChat = false;
                                    }}
                                />
                            </div>
                        </>
                    )}

                    <div className="ai-assist-content-wrapper">
                        {aiChatbotIndex >= 0 && messages.length <= 0 && (
                            <div className="card-wrapper">
                                {!isRelevaltSearch && (
                                    <MostRelevantSearch
                                        relevantQuestion={
                                            AiAssistData.welcome[0]
                                                .messageContent[aiChatbotIndex]
                                        }
                                        relevantQuestionHandler={
                                            relevantQuestionHandler
                                        }
                                    />
                                )}
                            </div>
                        )}
                        {messages && (
                            <div className="ai-assist-content">
                                {messages.map((message, index) => (
                                    <div
                                        className="custom-list"
                                        key={`${index.toString()}`}
                                    >
                                        {renderChatContent(message, index)}
                                    </div>
                                ))}
                                {respLoading && (
                                    <EonUiPreloaderSquares
                                        className="preloader"
                                        placeholder="preloader"
                                    />
                                )}
                                {messages.length > 1 && !disableInput && (
                                    <Feedback
                                        dataToSend={dataToSend}
                                        aiChatbotIndex={aiChatbotIndex}
                                        createIncidentHandler={
                                            createIncidentFromChatBot
                                        }
                                    />
                                )}
                                {queRepeatError && (
                                    <div className="repeat-error">
                                        {t("chatBot.duplicateError")}
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                    {confirmTicketCreation && incidentResponse?.number ? (
                        <div className="ticket-success">
                            <EonUiIcon
                                className="user-icon"
                                name="radio_checkbox_tick"
                                placeholder="radio_checkbox_tick"
                            />
                            <p>
                                {t("chatBot.ticketSuccessMsg")}
                                {incidentResponse?.number}
                            </p>
                        </div>
                    ) : (
                        confirmTicketCreation &&
                        !cancelIncidentCreation && (
                            <p className="failure-msg">{t("chatBot.ticketErrorMsg")}</p>
                        )
                    )}
                </div>
            </div>
            <div className="ai-assist-footer">
                {inputVal && (
                    <div className="user-heading">
                        <EonUiIcon
                            className="user-icon"
                            name="user"
                            placeholder="user-icon"
                        />
                        {firstName}
                    </div>
                )}
                <div className="input-wrapper">
                    <div style={{ width: "100%" }}>
                        <SearchList
                            itemClickedCallback={setInputQueryFromQuestion}
                            queryChange={(value) => setInputVal(value)}
                            isAdvanceSearch
                            limitedHeight
                            listReverse
                            defaultInputVal={inputVal}
                        />
                    </div>
                    <EonUiButton
                        title={t("chatBot.sendbtn")}
                        placeholder={""}
                        onClick={() => {
                            setInputQueryFromQuestion(inputVal);
                        }}
                        className="margin-top-space"
                        icon="send"
                    ></EonUiButton>
                </div>
            </div>
            <div className="disclaimer-text">{t("chatBot.disclaimerText")}</div>
        </>
    );
};

export default AiAssist;
