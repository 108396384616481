import React from "react";
import { DataType } from "./ElSearchAdvanceHelpers";
import { SelectFilterButton } from "./SelectFilterButton";
import { FilterSetters } from "./elsearch.domain";

interface SearchFiltersInterface {
    setters: FilterSetters;
    dataType: DataType;
}

const SearchFiltersTop: React.FC<SearchFiltersInterface> = ({
    setters,
    dataType
}) => {

    return (
        <div className="filter-conatiner">
            <div className="filter-tabs filter-tabs-top">
                <SelectFilterButton
                    setters={setters}
                    textkey="filterPage.filterMenu.all"
                    id="default"
                    clazz={dataType === "all" ? "active" : ""}
                    dataType="all"
                    value={[]}
                />
                <SelectFilterButton
                    setters={setters}
                    textkey="filterPage.filterMenu.app"
                    clazz={dataType === "apps" ? "active" : ""}
                    dataType="apps"
                    value={"app"}
                />
            </div>
        </div>
    );
};
export default SearchFiltersTop;
