import React from "react";
import ElSearchAdvance from "../../components/el-search-advance/ElSearchAdvance";

const SearchResultPage: React.FC = () => {
    return (
        <div className="result-page">
            <ElSearchAdvance />
        </div>
    );
};
export default SearchResultPage;
