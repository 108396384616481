import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./CardComponent.scss";
import { sortArray } from "../../../util/common-util";
import {
    RoomBookingInterface,
    CapatityObjInterface,
} from "../../room-booking/RoomBookingInterface";
import SVGIcon from "../../svg-icon/SVGIcon";
import useRoomBookingStore from "../../../store/roomBookingStore";

type RoomCardProps = {
    cardData: RoomBookingInterface;
    onCardClick: (cardData: RoomBookingInterface) => void;
};

function RoomCard({ cardData, onCardClick }: RoomCardProps) {
    const availability = ["free", "partial", "busy"];
    const seating_ids = cardData.seating_ids;
    return (
        <div onClick={() => onCardClick(cardData)} className="card-layout">
            <div className="card-row">
                <span
                    className={`icon-theme icon-theme--${
                        availability[cardData.availability]
                    }`}
                ></span>
                {seating_ids && (
                    <span>
                        {seating_ids.map(
                            (
                                seatingObj: number | CapatityObjInterface,
                                idx: number
                            ) => {
                                if (typeof seatingObj === "object") {
                                    return (
                                        <div
                                            className="card-heading"
                                            key={`${idx.toString()}`}
                                        >
                                            {seatingObj.seating_type_name} |{" "}
                                            {seatingObj.capacity_max} {"pax"}
                                        </div>
                                    );
                                } else {
                                    return (
                                        <div
                                            className="card-heading"
                                            key={`${idx.toString()}`}
                                        ></div>
                                    );
                                }
                            }
                        )}
                    </span>
                )}
            </div>
            <div className="display-flex">
                <div>
                    <p className="card-text__bold">{cardData.object_name}</p>
                </div>
            </div>
            <div className="type-icon-wrapper">
                <SVGIcon
                    url={`./icons/${
                        cardData.object_type_id === 2
                            ? "one_hotseat"
                            : "four_room"
                    }.svg`}
                />
            </div>
        </div>
    );
}

interface ChildProps {
    saveBookingModalHandler: (cardData: RoomBookingInterface) => void;
}

const CardComponent: React.FC<ChildProps> = ({
    saveBookingModalHandler,
}) => {
    const { capacityInfo, numberOfParticipants, roomDetailsData } = useRoomBookingStore();

    const [cards, setCards] = useState<RoomBookingInterface[]>([]);
    const { t } = useTranslation("room-booking");

    const handleCardClick = (cardData: RoomBookingInterface) => {
        saveBookingModalHandler(cardData);
    };

    const refineMinCapValue = (
        minCapValue: number,
        maxCapacity: number
    ): number => {
        return minCapValue === 0 || minCapValue > maxCapacity
            ? maxCapacity
            : minCapValue;
    };

    useEffect(() => {
        const modifiedCards = roomDetailsData.map(
            (cardData: RoomBookingInterface) => {
                if (cardData.minCapValue) {
                    return cardData;
                }
                let minCapValue = 0;
                const seats = cardData?.seating_ids
                    .slice(0, 4)
                    .map((seating_id: number) => {
                        const filteredCardArray = capacityInfo.filter(
                            (capacity: CapatityObjInterface) => {
                                if (capacity.seating_id === seating_id) {
                                    minCapValue = refineMinCapValue(
                                        minCapValue,
                                        capacity.capacity_max
                                    );
                                    return true;
                                }
                                return false;
                            }
                        );
                        return filteredCardArray[0];
                    });

                cardData.seating_ids = seats;
                cardData.minCapValue = minCapValue;
                minCapValue = 0;
                return cardData;
            }
        );

        const sortedCard = sortArray(modifiedCards, "minCapValue");
        const canAccomodate: RoomBookingInterface[] = [];
        const canNotAccomodate: RoomBookingInterface[] = [];

        sortedCard.forEach((obj: RoomBookingInterface) => {
            if (obj.minCapValue && parseInt(numberOfParticipants) <= obj.minCapValue) {
                canAccomodate.push(obj);
            } else {
                canNotAccomodate.push(obj);
            }
        });
        setCards([...canAccomodate, ...canNotAccomodate.reverse()]);
    }, [roomDetailsData, parseInt(numberOfParticipants)]);

    return (
        <div className="room-card-wrapper">
            <div className="card-wrapper">
                {cards &&
                    cards?.map(
                        (cardData: RoomBookingInterface, index: number) => {
                            return (
                                <RoomCard
                                    key={`${index.toString()}`}
                                    cardData={cardData}
                                    onCardClick={handleCardClick}
                                />
                            );
                        }
                    )}
            </div>
            <div className="availability-pointers__label">
                <div className="indicator">
                    <span className="icon-theme icon-theme--free"></span>
                    <p className="label__text">
                        {t("availableLabel")}
                    </p>
                </div>
                <div className="indicator">
                    <span className="icon-theme icon-theme--partial"></span>
                    <p className="">
                        {t("partiallyFree")}
                    </p>
                </div>
                <div className="indicator">
                    <span className="icon-theme icon-theme--busy"></span>
                    <p className="">{t("busyLabel")}</p>
                </div>
            </div>
        </div>
    );
};

export default CardComponent;
